<template>
  <div class="container">
    <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div class="col-md-5">
            <div class="form-group mb-2 shadow">
                <input type="month" v-model="data.date" class="form-control" placeholder="Fecha">
            </div>
            
            <div class="input-group mb-2 mr-sm-2 shadow">
                <div class="input-group-prepend">
                    <div class="form-group">
                        <select v-model="data.type" class="form-control" id="exampleFormControlSelect1">
                            <template  v-for="item in optionsFindBy">
                                <option :key="item.id" :value="item">{{item.description}}</option>
                            </template>
                            <!-- <option value="2">Buscar por número de cuenta</option> -->
                        </select>
                    </div>
                </div>
                <input type="text" v-model.trim="data.search" class="form-control" id="inlineFormInputGroupUsername2" :placeholder="this.data.type && this.data.type.placeholder">
                <div class="input-group-prepend">
                    <div class="input-group-text bg-primary text-white" style="cursor:pointer;" @click="search()">
                        Buscar
                    </div>
                </div>
            </div>
            <template v-if="respSearch && respSearch.code == 200">
                <div class="card border-primary mb-3" v-for="item in respSearch.data" :key="item.id"  >
                    <div class="card-body text-primary d-flex flex-row justify-content-between align-items-center"  >
                        <div class="text-black">
                            <p class="card-text mb-0">Guia: {{item.id}}</p>
                            <p class="card-text">{{item.client.name}}</p>
                        </div>
                        <div class="">
                            <a :href="`/guide/view-pdf/${item.package_id}`" target="_blank" style="text-decoration:none">Descargar</a>
                        </div>
                    </div>
                </div>
            </template>
            <div class="card border-danger mb-3"  v-if="respSearch && respSearch.code == 404">
                <div class="card-body text-primary"  >
                    <div class="text-black">
                        <p class="card-text text-center text-danger">{{respSearch.message}}</p>
                    </div>
                </div>
            </div>

        </div>



    </div>
  </div>
</template>

<script> 
import AppOptions from "../../config/AppOptions.vue"  
import {searchGuide} from "../../services/guide" 
import baseUrl  from "../../services/api";    
export default {
    data(){
        return {
            searchSuccess:true,
            respSearch:null,
            baseUrl,
            optionsFindBy:[
                {id:1,description:"Guía",placeholder:"Ingresa aquí tu guía"},
                {id:2,description:"Cuenta",placeholder:"Ingresa aquí tu cuenta"}
            ],
            data:{
                search:null,
                date:null,
                type:null
            }
        }
    },
    mounted(){
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;
        this.data.type = this.optionsFindBy[0]
    },
    methods:{
        async search(){
            if (!this.data || !this.data.search || !this.data.type || !this.data.date) {
                return
            }
            let data = {
                ...this.data,
                date: `${this.data.date}-01`,
                type: this.data.type.id
            }
            let loader = this.$loading.show({
                container: this.$refs.loginRef
            });
            try {
                let resp = await searchGuide(data)
                loader.hide()
                this.respSearch = resp
                
                console.log('respuesta busqueda',resp);
                
            } catch (error) {
                console.log('error search',error);
                loader.hide()
            }
        }
    }
}
</script>

<style>

</style>