import { post, postFile } from "./api";

export const  getGuides = async (data, vue)=>{
    try {
        let route = "guide/get-by-office"
        let loading = vue.$loading.show();
        let resp = await post({route, data})
        loading.hide()
        return resp
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  assignedOperated = async (data)=>{
    try {
        var route = "guide/assigned_operated"
        return await post({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  unAssignOperated = async (data)=>{
    try {
        var route = "guide/unassign_operated"
        return await post({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};


export const  downloadGuides = async (data)=>{
    try {
        var route = "guide/download-excel-with-guides"
        return await postFile({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  searchGuide = async (data)=>{
    try {
        var route = "guide/search"
        return await post({route, data})
    } catch (error) {
        console.log("search Failed", error);
        throw new Error("search Failed")
    }
};


export const assignedMassive = async (data)=>{
    try {

        var route = "guide/bulk-assign-operator-guides"
        return await post({route, data})
    } catch (error) {
        console.log("Service assigned_massive Failed", error);
        throw new Error("Service assigned_massive Failed")
    }
};


export const createGuides = async (data)=>{
    try {

        var route = "guide/create"
        return await post({route, data})
    } catch (error) {
        console.log("Service assigned_massive Failed", error);
        throw new Error("Service assigned_massive Failed")
    }
};


export const getForPdf = async (data)=>{
    try {

        var route = "guide/for-pdf"
        return await post({route, data})
    } catch (error) {
        console.log("Service assigned_massive Failed", error);
        throw new Error("Service assigned_massive Failed")
    }
};


export const getInform = async (data)=>{
    try {

        var route = "guide/inform"
        data = {
            filters:data
        }
        return await post({route, data})
    } catch (error) {
        console.log("Service assigned_massive Failed", error);
        throw new Error("Service assigned_massive Failed")
    }
};

export const  uploadGuide = async (data)=>{
    try {
        let route = "guide/upload-guides"
        return await post({route, data})
    } catch (error) {
        console.log("Service uploadGuide Failed", error);
        throw new Error("Service uploadGuide Failed")
    }
};







