import { post } from "./api";

export const  getPermissions = async ()=>{
    try {
        let route = "permission/show"

        let data ={
            "query": "",
            "limit": 300,
            "page": 1,
        }
        return await post({route:route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};




