import Vue from 'vue'
import App from './App.vue'
import routes from './config/AppRoutes'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import vueHljs from 'vue-hljs'
import hljs from 'highlight.js'
import DateRangePicker from 'vue2-daterange-picker'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueColorPicker from '@caohenghu/vue-colorpicker'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueMaskedInput from 'vue-maskedinput'
import wysiwyg from 'vue-wysiwyg'
import { VueMasonryPlugin } from 'vue-masonry'
import vSelect from 'vue-select'
import VueLoading from 'vue-loading-overlay';
import {ServerTable, ClientTable} from 'vue-tables-2';
import VModal from 'vue-js-modal'
import VueSweetalert2 from 'vue-sweetalert2';
import tooltip from './directives/tooltip'
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files



// VueStudio SCSS
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-hljs/dist/style.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/styles.scss'
import 'vue-select/dist/vue-select.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-datetime/dist/vue-datetime.css'
import can from './directives/can'
import store from './vuex/store'


Vue.config.productionTip = false

Vue.use(wysiwyg, { maxHeight: '300px'})
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(vueHljs, { hljs })
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueMasonryPlugin)
Vue.use(VueLoading);

Vue.use(ClientTable, {
  perPage: 15,
  texts: {
    count: "Montrando del {from} al {to} de {count} Registros|{count} Registros|1 Registro",
    first: "First",
    last: "Last",
    filter: "",
    records:"......",
    filterPlaceholder: "Buscar registro",
    limit: "",
    page: "Page:",
    noResults: "Sin resultados",
    filterBy: "{column}",
    loading: "Cargando...",
    defaultOption: "Todas",
    columns: "Columns"
  }
  
})
Vue.use(ServerTable, {
  perPage: 15,
  texts: {
    count: "Montrando del {from} al {to} de {count} Registros|{count} Registros|1 Registro",
    first: "First",
    last: "Last",
    filter: "",
    records:"......",
    filterPlaceholder: "Buscar registro",
    limit: "",
    page: "Page:",
    noResults: "Sin resultados",
    filterBy: "{column}",
    loading: "Cargando...",
    defaultOption: "Todas",
    columns: "Columns"
  }
  
})

Vue.use(VModal)
Vue.use(Datetime)

Vue.use(VueSweetalert2);

Vue.use(tooltip)
Vue.use(can, {
  superAdmin:"SUPER_ADMIN"
})





Vue.component('v-select', vSelect)

Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('vue-color-picker', VueColorPicker)
Vue.component('vue-masked-input', VueMaskedInput)
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('datetime', Datetime);

const router = new VueRouter({
	routes,
  mode: 'history'
})
router.afterEach((to) => {
  if (to.name !== "search-guide" && to.name !== "GuidePdf") {
    let token = localStorage.getItem('token')
    if (!token) {
      if (to.name !== "login") {
        router.push({ name: "login"})
        console.log("No esta autenticado");
      }
    }
  }
})


new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
