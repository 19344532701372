<template >
    <div class="col-md-2">
        <a href="#" class="btn btn-success text-center" @click.prevent="show" >Filtros <i class="fas fa-filter"></i></a>
        <modal :name="this.nameModal" :clickToClose="false" height="auto">
            <div tabindex="-1" role="dialog">
                <div class="modal-content">
                    
                    <div class="modal-header">
                        <h5 class="modal-title">Filtrar</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">

                            <div class="col-md-6 mt-2">
                                <label for="cycles" class="form-label">Fecha de inicio</label>
                                <input type="date" class="form-control" v-model="filter.start">
                            </div>
    
                            <div class="col-md-6 mt-2">
                                <label for="cycles" class="form-label">Fecha de fin</label>
                                <input type="date" class="form-control" v-model="filter.end">
                            </div>
                        </div>

                        <div class="col-md-12 mt-2 form-group" >
                            <label for="users" class="form-label">Estados</label>
                            <v-select
                                :options="states" 
                                placeholder="Estado" 
                                :reduce="item => item.id" 
                                label="name" 
                                v-model="filter.statusesId"
                                multiple
                                inputId="states"
                            >
                            </v-select>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal" @click.prevent="cleanFilters">Limpiar</button>
                        <!-- <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide()">Cerrar</button> -->
                        <button type="button" class="btn btn-primary" @click.prevent="emitFilter" >Filtrar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                nameModal:"filterScheduling",
                filter:{},
                states:[
                    {id:6,name:'EN COLA'},
                    {id:7,name:'EN PROCESO'},
                    {id:8,name:'PROCESADA'},
                    {id:9,name:'ERROR'},
                ]
            }
        },
        methods: {
            hide(){
                this.$modal.hide(this.nameModal);
            },
            show(){
                this.$modal.show(this.nameModal);
            },
            emitFilter(){
                if (this.filter && this.filter.start == '') {
                    delete this.filter.start 
                }
                if (this.filter && this.filter.end == '') {
                    delete this.filter.end 
                }
                this.$emit("handlerFilter",this.filter)
                this.hide()
            },
            cleanFilters(){
                this.filter = {}
                this.$emit("handlerFilter",null)
                this.hide()
            },
        },
    }
</script>