import { post } from "./api";
import sha1 from "js-sha1";

export const  getComplements = async (data)=>{
    try {
        let route = "auth/login"
        data.device_name = navigator.userAgent
        return await post({route:route, data: {...data, password:sha1(data.password)}})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};



export const  register = async (data)=>{
    try {
        let route = "user/register"
        // data.device_name = navigator.userAgent
        return await post({
            route:route, 
            data: {
                ...data,
                password:sha1(data.password),
                password_confirmation:sha1(data.password)
            }})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  searchUsers = async (data)=>{
    try {
        let route = "user/get_by_permission"
        return await post({
            route, 
            data
        })
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};


export const  getUsersOffice = async (data)=>{
    try {
        let route = "user/list_users_office"
        return await post({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  assingPermissions = async (data)=>{
    try {
        let route = "user/add_permissions"
        return await post({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  deletePermissions = async (data)=>{
    try {
        let route = "user/remove_permissions"
        console.log(route);
        return await post({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  deleteRoles = async (data)=>{
    try {
        let route = "user/remove_roles"
        return await post({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  assingRoles = async (data)=>{
    try {
        let route = "user/add_roles"
        return await post({route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  getInfoUser = async (data)=>{
    try {
        let route = "user/get_info"
        return await post({route, data})
    } catch (error) {
        console.log("Service get_info Failed", error);
        throw new Error("Service get_info Failed")
    }
};


export const  addCompany = async (data)=>{
    try {
        let route = "user/add-company"
        return await post({route, data})
    } catch (error) {
        console.log("Service get_info Failed", error);
        throw new Error("Service get_info Failed")
    }
};



export const  changePassword = async (data)=>{
    try {
        let route = "user/change-password"

        data = {

            ...data,
            newPassword: sha1(data.newPassword)

        }
        return await post({route, data})
    } catch (error) {
        console.log("Service get_info Failed", error);
        throw new Error("Service get_info Failed")
    }
};



