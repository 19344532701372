import Vue from "vue";
import Vuex from "vuex";
import {getCompanies}  from '../services/company'
import {getCycles}  from '../services/cycle'
import { getPermissions,getRoles,logout } from '../services/auth'
import {getInfoUser} from '../services/user'
import { createGuides } from '../services/guide'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        companies:[],
        cycles:[],
        permissions:[],
        roles:[],
        permissionsByUser:[],
        rolesByUser:[],
        userInfo:null,
        penddingGuides: [],
        progress:null,
        payload: null
    },
    actions:{
        async getCompanies({commit}){
            try {
                let resp = await getCompanies({})
                commit("setCompanies",resp.data)
            } catch (error) {
                console.log(error);
            }
        },
        async getCycles({commit}){
            try {
                let resp = await getCycles({})
                commit("setCycles",resp.data)
            } catch (error) {
                console.log(error);
            }
        },
        async getPermissions({commit}){
            try {
                let resp = await getPermissions({})
                // console.log('permmission', resp);
                commit("setPermissions",resp.data)
            } catch (error) {
                console.log(error);
            }
        },
        async getRoles({commit}){
            try {
                let resp = await getRoles({})
                commit("setRoles",resp.data)
            } catch (error) {
                console.log(error);
            }
        },
        async getUserInfo({commit}){
            try {
                let resp = await getInfoUser({})
                if (resp.data) {
                    let permissions = resp.data.permissions.map((item) => item.name)
                    let roles = resp.data.roles.map((item) => item.name)
                    commit("setPermissionsByUser",permissions)
                    commit("setRolesByUser",roles)
                    permissions = JSON.stringify(permissions)
                    roles = JSON.stringify(roles)
                    localStorage.setItem("permissions",permissions)
                    localStorage.setItem("roles",roles)
                    
                    commit("setUserInfo",resp.data)
                }
            } catch (error) {
                console.log(error);
            }
        },
        async logout({commit}){
            try {
                let resp = await logout({})
                if (resp.code == 200) {
                    commit("setUserInfo",null)
                    localStorage.removeItem("permissions")
                    localStorage.removeItem("roles")
                    localStorage.removeItem("token")
                    localStorage.removeItem("user")
                    
                }
            } catch (error) {
                console.log(error);
            }
        },
        async sendShortFile({commit, state}){
            let guides = JSON.parse(localStorage.getItem("guidesPending"))
            commit("setPenddingGuides",guides)
            let data = state.payload
            let condition = state.penddingGuides.length
            let totalToProcess = condition
            let index = 1
            while (condition > 0) {
                let resp = await createGuides({
                    company_id: data.company.id,
                    payload: {
                        cycle: data.cycle.id,
                        companyId: data.company.id, 
                    },
                    guides: [state.penddingGuides[0]]
                })
                
                if (resp.code != 200) {
                    if (!localStorage.getItem("guidesError")) {
                        localStorage.setItem("guidesError", JSON.stringify([]))
                    }
                    let gError = JSON.parse(localStorage.getItem("guidesError"))
                    gError.push(state.penddingGuides[0])
                    localStorage.setItem("guidesError", JSON.stringify(gError))
                }
                state.penddingGuides.shift()
                commit("setPenddingGuides", {data: state.penddingGuides, payload: data})
                index++
                commit("setProgress", {total: totalToProcess, value: Math.round((index/totalToProcess)*100)})
                condition--
            }
            commit("setProgress", null)
        }
    },
    mutations:{
        setCompanies(state,data){
            state.companies = data
        },
        setCycles(state,data){
            state.cycles = data
        },
        setPermissions(state,data){
            state.permissions = data
        },
        setRoles(state,data){
            state.roles = data
        },
        setPermissionsByUser(state,data){
            state.permissionsByUser = data
        },
        setRolesByUser(state,data){
            state.rolesByUser = data
        },
        setUserInfo(state,data){
            state.userInfo = data
        },
        setPenddingGuides(){

            // console.log("setPenddingGuides");
            // state.penddingGuides = guides.data
            // state.payload = guides.payload
            // localStorage.setItem('guidesPending', JSON.stringify({data: guides.data, payload: guides.payload}))
        },
        setProgress(state,data){
            state.progress = data
        }
    }
})


export default store