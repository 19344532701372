import { get, post } from "./api";

export const  uploadFileService = async (data)=>{
    try {
        let route = "scheduling/upload-file"
        return await post({route, data})
    } catch (error) {
        console.log("Service uploadFileService Failed", error);
        throw new Error("Service uploadFileService Failed")
    }
};

export const  getComplementsService = async (data)=>{
    try {
        let route = "scheduling/get-complements"
        return await get({route, data})
    } catch (error) {
        console.log("Service uploadFileService Failed", error);
        throw new Error("Service uploadFileService Failed")
    }
};

export const  listScheduling = async (data)=>{
    try {
        let route = "scheduling/list-schedulings"
        return await post({route, data})
    } catch (error) {
        console.log("Service listScheduling Failed", error);
        throw new Error("Service listScheduling Failed")
    }
};

export const  changeStatusBulk = async (data)=>{
    try {
        let route = "guide/change-status-bulk"
        return await post({route, data})
    } catch (error) {
        console.log("Service changeStatusBulk Failed", error);
        throw new Error("Service changeStatusBulk Failed")
    }
};