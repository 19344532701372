<template>
  <div class="container-fluid bg-white ">
    <div class="row page_break" v-for="(guide, i) in guides" :key="i">
      <table border="1"  class="col-12 mb-custom-4">
        <tr>
          <td width="40%">
            <div class="d-flex" style="display: flex !important;flex-direction: row;justify-content: space-around;align-items: center;">
              <div> 
                  <img
                      src="/assets/img/guides/pg_log.PNG"
                      alt="logo de P&G company"
                      width="90px"
                    />
                  
              </div>
              <div>
                <p class="mb-0"> <b>PYG S.A.S</b></p>
                <p class="mb-0"> <b> NIT 809.005.093-2</b> </p>
                <p class="mb-0"><b>CARRERA 7 #14-26</b> </p>
                <p class="mb-0"><b>IBAGUE-TOLIMA</b></p>
                <p class="mb-0"><b>Tel. 6082610495</b></p>
                <p class="mb-0"><b>servicioalcliente@pyg-sas.com</b></p>
              </div>
            
            </div>
          </td>
          <td  width="20%"> 
            <div class="d-flex" style="justify-content: space-around;">
              <VueQr :size="90" :margin="0" :text="`${host}/guide/view-pdf/${guide.id}`" />
            </div>
          </td>
          <td  width="40%">
            <div class="d-flex" style="display: flex !important;flex-direction: row;justify-content: space-around;align-items: center;">
              <div> 
                  <img
                      src="/assets/img/guides/pg_log.PNG"
                      alt="logo de P&G company"
                      width="70px"
                    />
                  
              </div>
              <div>
                <p class="mb-0  span_colilla"> PYG S.A.S</p>
                <p class="mb-0 span_colilla">  NIT 809.005.093-2 </p>
                <p class="mb-0 span_colilla">CARRERA 7 #14-26 </p>
                <p class="mb-0 span_colilla">IBAGUE-TOLIMA</p>
                <p class="mb-0 span_colilla">Tel. 6082610495</p>
                <p class="mb-0 span_colilla">servicioalcliente@pyg-sas.com</p>
              </div>
            
            </div>
          
          </td>
        </tr>

        <tr>
          <td>
            <div class="d-flex" style="align-items: center;">
              <div class="d-flex" style="border-right: 1px solid">
                <p class="vertical vertical-letter">REMITENTE</p> 
              </div>
              <div style="margin-left: 10px;">
                <p class="mb-0">{{ setLabel('Nombe empresa:', guide.labels) }} {{ shortText(guide.company_name) }}</p>
                <p class="mb-0">{{ setLabel('Dirección empresa:', guide.labels) }} {{ shortText(guide.company_address) }}</p>
                <p class="mb-0">{{ setLabel('Nit empresa:', guide.labels) }} {{ shortText(guide.nit) }}</p>
                <p class="mb-0">{{ setLabel('Codigo postal empresa:', guide.labels) }} {{ shortText(guide.company_postal_code) }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex" style="justify-content: center;">
              <div >
                <p class="mb-1"><b>{{ setLabel('Orden:', guide.labels) }}  </b> </p> 
                <p class="mb-1"><b>{{ setLabel('Guia N°:', guide.labels) }}</b></p> 
              </div>
              <div style="margin-left: 10px;">
                <p class="mb-1">{{ shortText(guide.route_code) }}</p>
                <p class="mb-1">{{ shortText(guide.id) }} </p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex" style="justify-content: center; align-items: center;">
              <div >
                <p class="mb-1 span_colilla"><b>Remitente</b> </p> 
              </div>
              <div style="margin-left: 10px;">
                <p class="mb-0 span_colilla">{{ setLabel('Nombre empresa:', guide.labels) }} {{ shortText(guide.company_name) }}</p>
                <p class="mb-0 span_colilla">{{ setLabel('Dirección empresa:', guide.labels) }} {{ shortText(guide.company_address) }}</p>
                <p class="mb-0 span_colilla">{{ setLabel('Nit empresa:', guide.labels) }} {{ shortText(guide.nit) }} </p>
                <p class="mb-0 span_colilla">{{ setLabel('Codigo postal empresa:', guide.labels) }} {{ shortText(guide.company_postal_code) }} </p>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="d-flex" style="align-items: center;">
              <div class="d-flex" style="border-right: 1px solid">
                <p class="vertical vertical-letter">{{ setLabel('Destinatario', guide.labels) }}</p> 
              </div>
              <div  style="margin-left: 10px;">
                <p class="mb-0">{{ setLabel('Nombre cliente:', guide.labels) }} {{ shortText(guide.client_name) }}</p>
                <p class="mb-0">{{ setLabel('Direccion cliente:', guide.labels) }} {{ shortText(guide.address) }}</p>
                <p class="mb-0">{{ setLabel('Matricula - paquete:', guide.labels) }} {{ shortText(guide.package_name) }}</p>
                <p class="mb-0">{{ setLabel('Codigo posta:', guide.labels) }} {{ shortText(guide.company_postal_code) }}</p>
                <p class="mb-0">{{ setLabel('Departamento - municipio:', guide.labels) }} {{ shortText(guide.department_name) }} - {{ shortText(guide.city_name) }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex" style="justify-content: center;">
              <div >
                <p class="mb-1"><b> {{ setLabel('Peso', guide.labels) }} </b> </p>
                <p class="mb-1"><b> {{ setLabel('Tarifa', guide.labels) }} </b> </p> 
                <p class="mb-1"><b> {{ setLabel('Devolucion', guide.labels) }}</b> </p> 
              </div>
              <div style="margin-left: 10px;">
                <p class="mb-1">{{ shortText(guide.weight) }}</p>
                <p class="mb-1">{{ shortText(guide.rate) }}</p>
                <p class="mb-1">{{ setLabel('Infoter', guide.labels) }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex" style="justify-content: center; align-items: center;">
              <div >
                <p class="mb-1 span_colilla"><b>{{ setLabel('Destinatario', guide.labels) }}</b> </p> 
              </div>
              <div style="margin-left: 10px;">
                <p class="mb-0 span_colilla">{{ setLabel('Nombe cliente:', guide.labels) }}  {{ shortText(guide.client_name) }}</p>
                <p class="mb-0 span_colilla">{{ setLabel('Dirección clinte:', guide.labels) }} {{ shortText(guide.address) }}</p>
                <p class="mb-0 span_colilla">{{ setLabel('Matricula:', guide.labels) }} {{ shortText(guide.code_account) }}</p>
                <p class="mb-0 span_colilla">{{ setLabel('Código postal cliente:', guide.labels) }} {{ shortText(guide.postal_code) }}</p>
                <p class="mb-0 span_colilla">{{ setLabel('Departamento:', guide.labels) }} {{ shortText(guide.department_name) }}</p>
                <p class="mb-0 span_colilla">{{ setLabel('Municipio:', guide.labels) }} {{ shortText(guide.city_name) }}</p>
              </div>
            </div>
          
          </td>
        </tr>

        <tr>
          <td>
            <div class="d-flex" style="align-items: center;">
              <div class="d-flex" style="border-right: 1px solid">
                <p class="vertical vertical-letter">{{ setLabel('Recibo', guide.labels) }}</p> 
              </div>
              <div  style="margin-left: 10px;">
                <p class="mb-0" >
                  {{ setLabel('Firma cliente:', guide.labels) }}
                  <img v-if="guide.url" style="width:40px;height: 40px;" :src="basePath+'/storage/'+guide.url" :alt="basePath+'/storage/'+guide.url">
                </p>
                <p class="mb-0">{{ setLabel('Fecha-hora confirmado app:', guide.labels) }} {{ shortText(guide.confirmed_at) }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex" style="justify-content: center;">
              <div >
                <p class="mb-1"><b>{{ setLabel('Matricula', guide.labels) }}</b> </p> 
                <p class="mb-1"><b>{{ setLabel('Admintido', guide.labels)}}</b> </p>
              </div>
              <div style="margin-left: 10px;">
                <p class="mb-1">{{ shortText(guide.code_account) }}</p>
                <p class="mb-1">{{ shortText(guide.date_issue)}}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex" style="justify-content: center; align-items: center;">
              <div >
                <p class="mb-0 span_colilla"><b>{{ setLabel('Guia N°', guide.labels) }}</b> </p> 
                <p class="mb-0 span_colilla"><b>{{ setLabel('Orden', guide.labels) }}</b> </p> 
                <p class="mb-0 span_colilla"><b>{{ setLabel('Matricula', guide.labels) }}</b> </p> 
                <p class="mb-0 span_colilla"><b>{{ setLabel('Admintido', guide.labels)}}</b> </p> 
              </div>
              <div style="margin-left: 10px;">
                <p class="mb-0 span_colilla">{{ shortText(guide.id) }}</p>
                <p class="mb-0 span_colilla">{{ shortText(guide.route_code) }}</p>
                <p class="mb-0 span_colilla">{{ shortText(guide.code_account) }}</p>
                <p class="mb-0 span_colilla">{{ shortText(guide.date_issue) }}</p>
              </div>
            </div>
          </td>
        </tr>
        <!-- <pre>{{ guide }}</pre> -->
      </table>
      <!-- <br>
      <br>
      <br>
      <br> -->
    </div>
  </div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
import { getForPdf } from '../../services/guide';
import VueQr from 'vue-qr'
import {basePath} from '../../services/api'



export default {
  components: {
    VueQr
  },
  data() {
    return {
      filter: null,
      guides: [],
      host:null,
      basePath
    };
  },
  async mounted() {
    this.host = window.location.origin
    AppOptions.appWithoutSidebar = true;
    AppOptions.appWithoutHeader = true;
    AppOptions.appContentFullWidth = true;
    if (this.$route.params.id && this.$route.params.id != 'null') {
      localStorage.setItem("guideFilter", JSON.stringify({p_guide_id: this.$route.params.id}))
    }
    
    this.filter = JSON.parse(localStorage.getItem("guideFilter"))

    let flag = true
    let limit = 30;
    let page = 1;
    let quantityRecords = limit

    while (flag) {
      let loader = this.$loading.show({}, {
                    // Pass slots by their names
                    after: page > 1 && `Cargando ${quantityRecords * page > this.guides[0]?.count ? this.guides[0]?.count: quantityRecords * page} de ${this.guides[0]?.count} guías.`,
                });
      let resp = await getForPdf({filters: this.filter, page, limit})

      window.customResponse = resp
      // flag = false;
      loader.hide()
      if(resp.data.length < limit){
        flag = false
      }else{
        page++
      }
      this.guides = this.guides.concat(resp.data)
    }

  },
  methods: {
    shortText(text, limit = 30){
      try {
        if(text && text.length >= limit){
          return text.slice(0, limit);
        }
        return text
        
      } catch (error) {
        return text
      }
    },
    setLabel(label, labels = null){
      if (labels && JSON.parse(labels)[label]) {
        let newLabels = JSON.parse(labels)
        return newLabels[label]
      }
      return label
    }
  },
};
</script>

<style scoped>
  .td-days {
    /* text-align: center; */
  }
  .vertical {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
  .text-center {
    text-align: center;
  }
  table {
    border-collapse: collapse;
  }
  p {
    font-size: 10px;
  }
  .span_colilla{
    font-size: 8px !important;
  }
  .vertical-letter {
    font-weight: bold;
    font-size: 5px;
    padding: 0px;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0px;
    font-weight: bold;
    text-align: center;
  }
  tr {
    border: solid 1px black
  }
  td{
    border: solid 1px black !important;
  }

  .border-div{
    border: solid .1px black !important;
  }

  .custom-row {
    /* --bs-gutter-x: 16px; */
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.letter{
  font-size:12px;
}

.bold{
  font-weight: bold;
}

.mb-custom-4{
  margin-bottom: 45px !important;
  /* display:block;
  page-break-before:always; */
}


@media print {
    .page_break { display: block; page-break-after: always; }
}



</style>
