var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content",attrs:{"id":"content"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v("Lista de archivos procesados")]),_c('div',{staticClass:"row justify-content-center"},[_c('FiltersScheduling',{on:{"handlerFilter":_vm.handlerFilter}})],1),_c('v-server-table',{ref:_vm.ref_table,class:['table-sm'],attrs:{"columns":_vm.columns,"options":{
                requestFunction: _vm.requestFunction,
                headings: _vm.headings,
                filterable:false,
                filterByColumn: false,
                sortable: [],
            }},on:{"pagination":_vm.handledPagination},scopedSlots:_vm._u([{key:"status.name",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"rounded p-1 text-white",style:({background: row.status.color, textAlign: 'center'})},[_c('span',[_vm._v(_vm._s(row.status.name))])])]}},{key:"Opciones",fn:function(ref){
            var row = ref.row;
return [_c('a',{directives:[{name:"can",rawName:"v-can",value:({permissions:['cambiar_estado_masivo_de_guias_a_partir_de_un_archivo_cargado_previamente'], doc: 'Cambiar estado de guias apartir de un archivo ya cargado'}),expression:"{permissions:['cambiar_estado_masivo_de_guias_a_partir_de_un_archivo_cargado_previamente'], doc: 'Cambiar estado de guias apartir de un archivo ya cargado'}"}],staticClass:"btn btn-primary btn-sm",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handlerEdit(row)}}},[_c('i',{staticClass:"far fa-edit"})])]}}])})],1)]),_c('ChangeState',{ref:"modalState",on:{"handlerChangeState":_vm.handlerChangeState}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }