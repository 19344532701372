<template>
    <div id="content" class="app-content">
        <div class="card">
            <div class="card-body">
                <h3>Ver ususarios</h3>
                <div class="row">
                    <!-- Filtros acá -->
                </div>
                <v-server-table  :columns="columns" ref="users_table"  :options="{
                    requestFunction,
                    headings,
                    filterable:false,
                }">

                <template slot="name" slot-scope="{row}">
                    <div>
                        {{ row.name }} {{ row.surname }}
                    </div>
                </template>

                
                <template slot="permisos" slot-scope="{row}">
                    <div v-for="userCompany in row.user_companies" :key="userCompany.id">
                        <div v-b-toggle="'permissions-collapse'+row.id+userCompany.id"> {{ userCompany.company.name }} <i class="fa-solid fa-eye"></i></div>
                        <b-collapse :id="'permissions-collapse'+row.id+userCompany.id" class="mt-2">
                            <b-card>
                                <a href="#" class="btn btn-sm  btn-success" @click.prevent="setUserCompany(userCompany,'assignPermissions')" >
                                    <i class="fa-solid fa-plus"></i>
                                </a>
                                <div v-if="userCompany.permissions.length > 0">
                                    <div class="text-success" v-for="permission in userCompany.permissions" :key="permission.id">
                                        {{ permission.description }} <a style="color:red;" href="#" @click.prevent="deletPermission({permission_id: permission.id, userCompanyId:userCompany.id})" ><i class="fa-solid fa-trash-can"></i></a> 
                                    </div>
                                </div>
                                <div v-else>
                                    No tiene permisos en esta sede
                                </div>
                            </b-card>
                        </b-collapse>
                    </div>

                </template>

                <template slot="roles" slot-scope="{row}">
                    <div v-for="userCompany in row.user_companies" :key="userCompany.id">
                        <div v-b-toggle="'collapse_roles'+row.id+userCompany.id">{{ userCompany.company.name }} <i class="fa-solid fa-eye"></i></div>
                        <b-collapse :id="'collapse_roles'+row.id+userCompany.id">
                            <a href="#" class="btn btn-sm btn-success mt-2" @click.prevent="setUserCompany(userCompany,'assignRoles')" >
                                <i class="fa-solid fa-plus"></i> 
                            </a>
                            <div v-for="role in userCompany.roles" :key="role.id">
                                <div v-b-toggle="'collapse'+row.id+role.id+userCompany.id">
                                    {{ role.name }} <a href="#" style="color:red;" @click.prevent="deleteRole({role_id: role.id, userCompanyId:userCompany.id})" ><i class="fa-solid fa-trash-can"></i></a>
                                </div>
                                <b-collapse :id="'collapse'+row.id+role.id+userCompany.id" class="mt-2">
                                    <b-card v-if="role.permissions.length > 0">
                                        <div v-for="rolePer in role.permissions" :key="rolePer.id" class="text-success">
                                            {{ rolePer.description }} 
                                        </div>
                                    </b-card>
                                    <b-card v-else>
                                        <div>
                                            El rol no tiene permisos asociados
                                        </div>
                                        <!-- I am collapsible content B! flksnlkfd -->
                                    </b-card>
                                </b-collapse>
                            </div>
                        
                        </b-collapse>
                    </div>

                        
                </template>



                <template slot="opciones" slot-scope="{row}">
                    <div >
                        <button v-can="{permissions: ['add_company'], doc: 'Agragar empresa a un usuario.'}" class="btn btn-sm btn-dark" @click.prevent="showModalAdd(row)" >Agregar empresa</button>
                        <button v-can="{permissions: ['change_password'], doc: 'Cambiar contraseña de un usuario.'}" class="btn btn-sm btn-success" @click.prevent="showModalAdd(row, 'ChangePassword')" >Editar</button>
                    </div>
                </template>
                </v-server-table>
            </div>
        </div>
        <AssignPermissionsByCompany :userCompany="userCompany" @successAssignPermmisions="successAssignPermmisions"/>
        <AssignRolesByCompany :userCompany="userCompany" @successAssignRoles="successAssignRoles"/>
        <AddCompany  :user="user" @finish="refreshPageTable('users_table')" />
        <ChangePassword :user="user" @finish="refreshPageTable('users_table')" />
    </div>
</template>


<script>
import { getUsersOffice,deletePermissions,deleteRoles } from '../../services/user'
import AssignPermissionsByCompany from './components/AssignPermissionsByCompany.vue'
import AssignRolesByCompany from './components/AssignRolesByCompany'
import AddCompany from './components/AddCompany'
import ChangePassword from './components/ChangePassword'

export default {
    components: {
        AssignPermissionsByCompany, 
        AssignRolesByCompany,
        AddCompany,
        ChangePassword
    },
    data() {
        return {
            columns: ["name","document", "email","permisos","roles", "opciones"],
            headings: {
                name: "Nombre",
                email: "Correo",
                document: "Documento"
            },
            userCompany:null,
            user: null
        }
    },
    methods: {
        refreshPageTable(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].setPage(this.page);
            }
        },
        requestFunction(data){
            return getUsersOffice(data).catch(function (e) {
                console.log('error', e);
            });
        },
        showModal(nameModal){
            this.$modal.show(nameModal);
        },
        setUserCompany(data,nameModal=null){
            if (nameModal) {
                this.$modal.show(nameModal);
            }
            console.log(data,nameModal);
            this.userCompany = data

        },
        successAssignPermmisions(){
            this.refreshPageTable("users_table")
        },
        successAssignRoles(){
            this.refreshPageTable("users_table")
        },
        deletPermission(data){
            this.$swal({
                position: 'center',
                icon: 'error',
                html: '¿ Seguro de eliminar el permiso para este usuario ?',
                // showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar',
                confirmButtonColor: '#f27474',
                showConfirmButton: true,
            }).then(async(result) => {
                if (result.isConfirmed) {
                    let loader = this.$loading.show({
                        container: this.$refs.loginRef
                    });
                    try {
                        data = {
                            userCompanyid:data.userCompanyId,
                            permissions:[data.permission_id],
                        }
                        console.log('eliminar',data);
                        let resp =   await deletePermissions(data)
                        console.log(resp);
                        if (resp.code == 200) {
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                html: 'Permiso eliminado correctamente',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.refreshPageTable("users_table")
                        }else{
                            this.$swal({
                                position: 'center',
                                icon: 'error',
                                html: 'No se pudo eliminar el permiso',
                                showConfirmButton: false,
                            }) 
                        }
                        loader.hide();
                        console.log(data);
                    } catch (error) {
                        loader.hide();
                        console.log(error);
                    }
                } 
            })
            
        },
        deleteRole(data){
            this.$swal({
                position: 'center',
                icon: 'error',
                html: '¿ Seguro de eliminar el rol para este usuario ?',
                // showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar',
                confirmButtonColor: '#f27474',
                showConfirmButton: true,
            }).then(async(result) => {
                if (result.isConfirmed) {
                    let loader = this.$loading.show({
                        container: this.$refs.loginRef
                    });
                    try {
                        data = {
                            userCompanyid:data.userCompanyId,
                            roles:[data.role_id],
                        }
                        console.log('roles',data);
                        let resp =   await deleteRoles(data)
                        console.log(resp);
                        if (resp.code == 200) {
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                html: 'Rol eliminado correctamente',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.refreshPageTable("users_table")
                        }else{
                            this.$swal({
                                position: 'center',
                                icon: 'error',
                                html: 'No se pudo eliminar el rol',
                                showConfirmButton: false,
                            }) 
                        }
                        loader.hide();
                        console.log(data);
                    } catch (error) {
                        loader.hide();
                        console.log(error);
                    }
                } 
            })
        },
        showModalAdd(row, modalName='addCompany'){
            this.user = row
            this.$modal.show(modalName)
        }

    }
}
</script>
<style >
    .VueTables__limit-field {
        margin-bottom: 10px !important;
        width: 10% !important;
    }
</style>