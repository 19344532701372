<template >
    <div>
        <modal :name="this.nameModal" :clickToClose="false" height="auto">
            <div tabindex="-1" role="dialog">
                <div class="modal-content" v-if="data_edit">
                    <div class="modal-header">
                        <h6 class="text-muted modal-title">Cambiar estado de las guias no ejecutadas(creada) para el archivo {{ data_edit.file_name }}</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <b-form-group class="col-md-12 mt-2">
                            <label for="cycles" class="form-label">Estados</label>
                            <v-select :options="statuses" placeholder="Seleccionar..." :reduce="item => item.id"  label="name" v-model="status"></v-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide()">Cerrar</button> -->
                        <button type="button" class="btn btn-primary" @click.prevent="changeState" >Cambiar de estado</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import { changeStatusBulk } from '../../../services/scheduling';
export default {
    data() {
        return {
            nameModal:"modalChangeState",
            data_edit:null,
            status:null,
            statuses:[
                {
                    id:4,
                    name: 'Confirmada'
                },
                {
                    id:5,
                    name: 'Cancelada'
                }
            ],
        }
    },
    mounted() {
       
    },
    methods:{
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },
        init(row){
            this.data_edit = row
        },
        async changeState(){
            if (!this.status) {
                this.$swal.fire({html:'Debe elegir un estado', icon:'warning'})
                return
            }
            let loading = this.$loading.show();
            try {
                let resp = await changeStatusBulk({
                    scheduling_id:this.data_edit.id,
                    new_status_id:this.status
                })
                if (resp.code == 200) {
                    this.$swal.fire({html:resp.message, icon:'success'})
                    this.status = null
                    this.$emit("handlerChangeState",resp)
                    this.hide()
                }else{
                    this.$swal.fire({html:resp.message, icon:'warning'})
                }
            } catch (error) {
                console.log(error);
                this.$swal.fire({html:'No se pudo realizar la accion', icon:'warning'})
            }
            loading.hide()
        }
       
    }
}
</script>