<template>
    <div>
        <modal :name="nameModal" :clickToClose="false" height="auto">
            <div :ref="nameModal" tabindex="-1" role="dialog" >
                <div class="modal-content">
                    <div class="modal-body">
                        <b-form-group class="col-md-12">
                            <label for="companies" class="form-label">Empresa</label>
                            <v-select :options="companies" placeholder="Empresa" label="name" v-model="company"></v-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click.prevent="addCompany">Agregar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import { addCompany } from '../../../services/user';
export default {
    props:{
        user: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            nameModal:"addCompany",
            company:null,
        }
    },
    mounted() {
    },
    methods:{
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },
        async addCompany(){
            if (!this.company) {
                return
            }

            let resp = await addCompany({
                userId: this.user.id,
                company_id: this.company.id,
                addTocompanyId: this.company.id,
            })

            if (resp.code == 200) {
               this.$swal({
                    position: 'center',
                    icon: 'success',
                    html: resp.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }else{
                this.$swal({
                    position: 'center',
                    icon: 'warning',
                    html: resp.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }

            this.hide()
            this.$emit('finish')

        }
    },
    computed: {
        ...mapState(['companies', 'userInfo'])
    },
}
</script>