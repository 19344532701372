import { post } from "./api";




export const  searchClients = async (data)=>{
    try {
        let route = "client/show"
        return await post({
            route:route, 
            data
        })
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};


