import { post } from "./api";
import sha1 from "js-sha1";

export const  login = async (data)=>{
    try {
        let route = "auth/login"
        data.device_name = navigator.userAgent
        return await post({route:route, data: {...data, password:sha1(data.password)}})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  logout = async (data)=>{
    try {
        let route = "auth/logout"
        return await post({route,data})
    } catch (error) {
        console.log("Service logout Failed", error);
        throw new Error("Service logout Failed")
    }
};



export const  getPermissions = async ()=>{
    try {
        let data ={
            "query": "",
            "limit": 100,
            "page": 1,
        }
        let route = "permission/show"
        return await post({route,data})
    } catch (error) {
        console.log("Service getPermissions Failed", error);
        throw new Error("Service getPermissions Failed")
    }
};


export const  getRoles = async ()=>{
    try {
        let data ={
            "query": "",
            "limit": 100,
            "page": 1,
        }
        let route = "role/show"
        return await post({route,data})
    } catch (error) {
        console.log("Service getRoles Failed", error);
        throw new Error("Service getRoles Failed")
    }
};