<template>
    <div>
        <button class="btn btn-danger mb-2" @click.prevent="downloadExcel()"> Excel (LAB) <i class="fas fa-file-excel"></i></button>
    </div>
</template>

<script>
const XLSX = require("xlsx");
import baseUrl from "../../../services/api";
export default {
    props:{
        filters:{
            type: Object
        }
    },
    data() {
        return {
            keyStorage: "guidesdownload",
            separator: "_____",
            millisecondsDuration:0,
            secoundsDuration:0,
            records:[]
        }
    },
    mounted() {
        console.log("mounted =>",XLSX);
    },
    methods: {
        async downloadExcel() {
            let loading = this.$loading.show();
            const startProcess = new Date();
            await this.getAndSaveRecords()
            const ednProcess = new Date();
            this.millisecondsDuration = ednProcess-startProcess;
            this.secoundsDuration = this.millisecondsDuration / 1000;
            loading.hide()
            this.notify()
        },

        async getAndSaveRecords() {
            let page = 1;
            let limit = 1000
            this.records = []
            let validation = true
            while (validation) {
                // let key = `${this.keyStorage}${this.separator}${page}`
                // localStorage.removeItem(key)
                // let records = JSON.parse(localStorage.getItem(key)) || []
                let headers = {
                        "Content-Type": "application/json",
                    }
                let token = localStorage.getItem('token')
                if (token) {
                    headers['Authorization'] = `Bearer ${token}`
                }
                const response = await fetch(`${baseUrl}guide/guides-per-chunks`,{
                    method: "POST",
                    body: JSON.stringify({filters: this.filters, page, limit}),
                    headers,
                });
                const { data } = await response.json();

                this.records = this.records.concat(data);
                // localStorage.setItem(key, JSON.stringify(records));
                if (data.length < limit) {
                    break;
                }
                page++;

                await this.sleep(1000); // 10,000 milisegundos = 10 segundos
            }
            this.buildFile()
        },

        buildFile() {

            // console.log("buildFile =>",XLSX);
            let records = this.getRecords()
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(records);
            XLSX.utils.book_append_sheet(wb, ws, 'records');
            XLSX.writeFile(wb, `${this.keyStorage}.xlsx`, { compression: true });
        },
        getRecords() {
            return this.records
        },

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        notify(){
            this.$swal.fire({
                title: 'La espera ha terminado !',
                html: `La descarga se ha tardado ${this.secoundsDuration} segundos y se han descargado ${this.records.length} registros en total.`,
                backdrop:false,
                allowOutsideClick:true,
                // timer: 2000,
                timerProgressBar: true,
            })
        }

    }

}
</script>