<template>
    <div>
        <modal :name="nameModal" :clickToClose="false" height="auto">
            <div :ref="nameModal" tabindex="-1" role="dialog" v-if="userCompany">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Asociar permisos adicionales para la empresa {{userCompany.company.name}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <b-form-group class="col-md-12">
                            <label for="permisions" class="form-label">Permisos</label>
                            <v-select :options="permissions" :reduce="item => item.id" placeholder="Permisos" label="description" v-model="selectedPermissions" multiple></v-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="assingPermissions" >Crear</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
// import {mapState,mapActions} from 'vuex'
import { getPermissions } from '../../../services/permission'
import {assingPermissions} from '../../../services/user'
export default {
    props:{
        userCompany:{
            // required:true,
            type:Object
        }
    },
    data(){
        return {
            nameModal:"assignPermissions",
            selectedPermissions:[],
            permissions:[]
        }
    },
    async mounted() {
        let resp = await getPermissions()
        this.permissions = resp.data
    },
    methods:{
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },
        async assingPermissions(){
            if (this.selectedPermissions.length > 0) {
                let loader = this.$loading.show({
                    container: this.$refs.loginRef
                });
                try {
                    let data = {
                        userCompanyid:this.userCompany.id,
                        permissions:this.selectedPermissions
                    }
                    let resp = await assingPermissions(data)
                    if (resp.code == 200) {
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            html: 'Permisos asignados correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.selectedPermissions = []
                        this.hide()
                        this.$emit('successAssignPermmisions')
                    }else{
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            html: 'No se pudieron asignar los permisos',
                            showConfirmButton: false,
                        }) 
                    }
                    loader.hide();
                } catch (error) {
                    loader.hide();
                    console.log(error);
                }
            }
        }
    },
    computed: {
        // ...mapState(["permissions"])
    },
}
</script>