<script>
const appSidebarMenu = [
  {
		text: 'Nav',
		is_header: true
	},
	{
		url: '/dashboard',
		icon: 'fa fa-laptop',
		text: 'Dashboard',
		permissions: ['view_dashboard']
	},
	// {
	// 	url: '/analytics',
	// 	icon: 'fa fa-chart-pie',
	// 	text: 'Analytics'
	// },
	{
		url: '/users/',
		icon: 'fa fa-users',
		text: 'Usuarios',
		// label: '6',
		children: [
			{ url: '/user/create', text: 'Crear', permissions: ['create_users'] },
			{ url: '/user/show', text: 'Ver', permissions: ['view_users'] },
			{ url: '/role/show', text: 'Roles', permissions: ['view_roles'] },
			// { url: '/email/detail', text: 'Detail' }
		]
	},

	// {
	// 	url: '/read/',
	// 	icon: 'fa fa-chart-bar',
	// 	text: 'Lecturas',
	// 	// label: '6',
	// 	children: [
	// 		{ url: '/read/show', text: 'Ver lecturas', permissions: ['view_reads'], doc: "Ver lecturas." },
	// 	]
	// },

	{
		// <i class="fa-solid fa-file-invoice"></i>
		url: '/guide/',
		icon: 'fa-solid fa-file-invoice',
		text: 'Guias',
		// label: '6',
		children: [
			{ url: '/guide/show', text: 'Ver guias', permissions: ['view_guides'], doc: "Ver guias." },
			{ url: '/archivos/show', text: 'Lista de archivos', permissions: ['listar_archivos_de_tareas'], doc: "Lista de archivos" },
		]
	},
// 	{
// 		is_divider: true
// 	},
// 	{
// 		text: 'Components',
// 		is_header: true
// 	},
// 	{
// 		url: '/widgets',
// 		icon: 'fa fa-qrcode',
// 		text: 'Widgets'
// 	},
// 	{
// 		url: '/ui/',
// 		icon: 'fa fa-heart',
// 		text: 'UI Kits',
// 		children: [
// 			{ url: '/ui/bootstrap', text: 'Bootstrap' },
// 			{ url: '/ui/buttons', text: 'Buttons' },
// 			{ url: '/ui/card', text: 'Card' },
// 			{ url: '/ui/icons', text: 'Icons' },
// 			{ url: '/ui/modal-notifications', text: 'Modal & Notifications' },
// 			{ url: '/ui/typography', text: 'Typography' },
// 			{ url: '/ui/tabs-accordions', text: 'Tabs & Accordions' }
// 		]
// 	},
// 	{
// 		url: '/form/',
// 		icon: 'fa fa-file-alt',
// 		text: 'Forms',
// 		children: [
// 			{ url: '/form/elements', text: 'Form Elements' },
// 			{ url: '/form/plugins', text: 'Form Plugins' },
// 			{ url: '/form/wizards', text: 'Wizards' }
// 		]
// 	},
// 	{
// 		url: '/table/',
// 		icon: 'fa fa-table',
// 		text: 'Tables',
// 		children: [
// 			{ url: '/table/elements', text: 'Table Elements' },
// 			{ url: '/table/plugins', text: 'Table Plugins' }
// 		]
// 	},
// 	{
// 		url: '/chart/',
// 		icon: 'fa fa-chart-bar',
// 		text: 'Charts',
// 		children: [
// 			{ url: '/chart/chart-js', text: 'Chart.js' },
// 			{ url: '/chart/chart-apex', text: 'Apexcharts.js' }
// 		]
// 	},
// 	{
// 		url: '/map',
// 		icon: 'fa fa-map-marker-alt',
// 		text: 'Map'
// 	},
// 	{
// 		url: '/layout',
// 		icon: 'fa fa-code-branch',
// 		text: 'Layout',
// 		children: [
// 			{ url: '/layout/starter-page', text: 'Starter Page' },
// 			{ url: '/layout/fixed-footer', text: 'Fixed Footer' },
// 			{ url: '/layout/full-height', text: 'Full Height' },
// 			{ url: '/layout/full-width', text: 'Full Width' },
// 			{ url: '/layout/boxed-layout', text: 'Boxed Layout' },
// 			{ url: '/layout/minified-sidebar', text: 'Minified Sidebar' }
// 		]
// 	},
// 	{
// 		url: '/page/',
// 		icon: 'fa fa-globe',
// 		text: 'Pages',
// 		children: [
// 			{ url: '/page/scrum-board', text: 'Scrum Board' },
// 			{ url: '/page/product', text: 'Products' },
// 			{ url: '/page/order', text: 'Orders' },
// 			{ url: '/page/gallery', text: 'Gallery' },
// 			{ url: '/page/search-results', text: 'Search Results' },
// 			{ url: '/page/coming-soon', text: 'Coming Soon Page' },
// 			{ url: '/page/error', text: 'Error Page' },
// 			{ url: '/page/login', text: 'Login' },
// 			{ url: '/page/register', text: 'Register'}
// 		]
// 	},
// 	{
// 		is_divider: true
// 	},
// 	{
// 		text: 'Users',
// 		is_header: true
// 	},
// 	{
// 		url: '/profile',
// 		icon: 'fa fa-user-circle',
// 		text: 'Profile'
// 	},
// 	{
// 		url: '/calendar',
// 		icon: 'fa fa-calendar',
// 		text: 'Calendar'
// 	},
// 	{
// 		url: '/settings',
// 		icon: 'fa fa-cog',
// 		text: 'Settings'
// 	},
// 	{
// 		url: '/helper',
// 		icon: 'fa fa-question-circle',
// 		text: 'Helper'
//   }
];

export default appSidebarMenu;
</script>
