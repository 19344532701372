import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const tooltip = {}
tooltip.install = (Vue) => {    
    Vue.directive('tooltip', {
        inserted(el, b) {
            tippy(el, b.value)
        }
    })
}
export default tooltip;