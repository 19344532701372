<template>
    <div>
        <button v-can="{permissions:['upload_file_task_scheduling'], doc: 'Subir archivo para programar tareas.'}" class="btn btn-primary" @click.prevent="show">{{  button_title }} <i class="fas fa-file-csv"></i></button>
        <modal :name="nameModal" :adaptive="true" :ref="nameModal" :clickToClose="false" height="auto">
            <div tabindex="-1" role="dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Cargar archivo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <b-form-group class="col-md-12 mt-2">
                            <label for="users" class="form-label">Seleccionar compañía</label>
                            <v-select :options="companies" placeholder="Seleccionar compañia" label="name" v-model="data.company"></v-select>
                        </b-form-group>
                        <!-- <b-form-group class="col-md-12 mt-2">
                            <label class="form-label">Seleccionar fecha de ejecusión</label>
                            <datetime 
                                v-model="data.executionDate"
                                inputClass="form-control"
                                type="datetime"
                                :auto="true"
                                value-zone="America/Bogota"
                                title="Seleccionar fecha de ejecusión"
                                placeholder="Seleccionar fecha de ejecusión"
                                :phrases="{ok: 'Seleccionar', cancel: 'Cancelar'}"></datetime>
                        </b-form-group> -->
                        <!-- <b-form-group class="col-md-12 mt-2">
                            <label class="form-label">Seleccionar mes y año</label>
                            <datetime 
                                v-model="data.monthAndYear"
                                inputClass="form-control"
                                type="date"
                                :auto="true"
                                value-zone="America/Bogota"
                                title="Seleccionar mes y año"
                                placeholder="Seleccionar mes y año"
                                :phrases="{ok: 'Seleccionar', cancel: 'Cancelar'}"></datetime>
                        </b-form-group> -->
                        <!-- <b-form-group class="col-md-12 mt-2">
                            <label for="users" class="form-label">Seleccionar ciclo</label>
                            <v-select :options="cycles" placeholder="Seleccionar ciclo" label="name" v-model="data.cycle"></v-select>
                        </b-form-group> -->
                        <b-form-group class="col-12 mt-2">
                            <label for="upload_files" class="col-12 upload-file-button"><i class="fas fa-upload"></i> 
                                <b>{{  button_title }}</b>
                                <p v-if="data.fileUpload" class="mb-0 text-success">{{data.fileUpload.name}}</p>
                            
                            </label>
                            <input type="file" id="upload_files" style="display:none" name="upload_files" @change="getFile" accept=".csv">
                            
                        </b-form-group>

                        <div class="col-md-12 mt-1" v-if="errors.length > 0">
                            <label class="text-center"><b>Errores</b></label>
                            <ul class="col-md-12">
                                <li class="text-danger" v-for="(error, index) in errors" :key="index">
                                    {{error}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                        <button  type="button" class="btn btn-primary" @click.prevent="uploadFile">Cargar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import { getComplementsService, uploadFileService } from "../../../services/scheduling"

// import {uploadGuide} from "../../../services/guide"

// import Papa from 'papaparse'
import { mapActions } from 'vuex'
export default {
    props:{
        action_id: {
            type: Number,
            required: true,
            default:() => 1
        },
        button_title: {
            type: String,
            required: true,
            default:() => "Cargar archivo"
        }
    },
    data() {
        return {
            nameModal:`upload_scheduling_${Math.random(1, 90000)}`,
            dateRange: {
                startDate: null,
                endDate: null,
            },
            data:{
                actionId: 1,
                cycle: null,
                fileUpload: null,
                // executionDate:null,
                monthAndYear:null,
                company:null,
            },
            cycles:[],
            companies: [],
            ready: false,
            guides: [],
            errors:[]
        }
    },
    mounted(){
        this.getComplements()
    },
    methods: {
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },
        async uploadFile(){
            try {
                this.applyValidations()
                // Papa.parse(this.data.fileUpload, {
                //     header: true,
                //     // chunk:(chunk)=>{
                //     //     console.log("chunk",chunk);
                //     // },
                //     worker: true,
                //     complete:(results)=>{
                //         localStorage.setItem('guidesPending', JSON.stringify({data: results.data, payload: this.data}))

                //         console.log("Finished:", results);

                //         this.sendGuides()
                //     }
                // });

                let loading = this.$loading.show();
                // let resp = await uploadGuide(this.setFormData())

                let resp = await uploadFileService(this.setFormData())
                loading.hide()
                if (resp.code == 200) {
                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        html: resp.message,
                        timer: 5000
                    })
                    this.data = {
                        actionId: this.action_id,
                        cycle: null,
                        fileUpload: null,
                        executionDate:null,
                        monthAndYear:null,
                    }
                    this.$emit("complete", resp)
                    this.hide()
                }else{
                    this.$swal({
                        position: 'center',
                        icon: 'warning',
                        html: resp.message,
                        timer: 5000
                    })
                    this.errors = resp.data
                }
                // this.hide() 
            } catch (error) {
                this.$swal({
                    position: 'center',
                    icon: 'warning',
                    html: error.message,
                    timer: 3000
                })
            }
            
        },
        async getFile(e){
            let image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (img) => {
                let type =  image.type.split('/')[1];
                let count = 0;
                let types = ['csv'];
                for (let index = 0; index < types.length; index++) {
                    if (types[index] == type) {
                        count = count+1
                    }
                }
                if (count > 0) {
                    let data = {
                        base: img.target.result,
                        image,
                    }
                    this.data.fileUpload = image

                    console.log(data);
                    console.log(image.name);
                }else{
                    console.table({msg:'invalid type.'+type});
                }

            }
            document.getElementById("upload_files").value=''

             
        },
        setFormData(){
            const form = new FormData();
            form.append("fileUpload", this.data.fileUpload);
            form.append("executionDate", "2022-06-06 10:00:00");
            form.append("companyId", this.data.company.id);
            // form.append("executionDate", this.data.executionDate);
            form.append("payload", JSON.stringify({
                cycle: null,
                companyId: this.data.company.id,
                month: "03",
                year: "2022",
            }));
            form.append("actionId", this.action_id);
            return form;
        },
        async getComplements(){
            let data = await getComplementsService()
            if (data.code == 200) {
                this.setCompanies(data.data.companies)
                this.setCycles(data.data.cycles)
            }
        },
        setCompanies(companies){
            return this.companies = companies
        },
        setCycles(cycles){
            return this.cycles = cycles
        },
        applyValidations(){
            if (!this.data.company || !this.data.company.id) {
                throw new Error("Debe seleccionar una compañía.")
            }
            
            // if (!this.data.monthAndYear || this.data.monthAndYear == "") {
            //     throw new Error("Debe especificar una fecha del ciclo.")
            // }

            // if (!this.data.cycle || !this.data.cycle.id) {
            //     throw new Error("Debe especificar un ciclo.")
            // }

            if (!this.data.fileUpload || this.data.fileUpload == "") {
                throw new Error("Debe seleccionar un archivo.")
            }
        },

        async sendGuides(){
            this.sendShortFile()
            this.hide()
            this.$swal({
                position: 'top-end',
                icon: 'success',
                html: `Las guías se procesarán en breve.`,
                showConfirmButton: false,
                timer: 5000
            }) 
        },
        ...mapActions(['sendShortFile'])

    }
}
</script>

<style>
    .upload-file-button {
        color: #8e648e;
        display: flex;
        flex-flow: column;
        text-align: center;
        border: 2px solid #8e648e;
        border-radius: 8px;
        height: auto;
        padding: 20px;
        margin: 5px;
        cursor: pointer;
        box-shadow: 5px 5px 5px #e3e3e3;
    }
</style>