var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content",attrs:{"id":"content"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v("Ver ususarios")]),_c('div',{staticClass:"row"}),_c('v-server-table',{ref:"users_table",attrs:{"columns":_vm.columns,"options":{
                requestFunction: _vm.requestFunction,
                headings: _vm.headings,
                filterable:false,
            }},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.name)+" "+_vm._s(row.surname)+" ")])]}},{key:"permisos",fn:function(ref){
            var row = ref.row;
return _vm._l((row.user_companies),function(userCompany){return _c('div',{key:userCompany.id},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('permissions-collapse'+row.id+userCompany.id),expression:"'permissions-collapse'+row.id+userCompany.id"}]},[_vm._v(" "+_vm._s(userCompany.company.name)+" "),_c('i',{staticClass:"fa-solid fa-eye"})]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":'permissions-collapse'+row.id+userCompany.id}},[_c('b-card',[_c('a',{staticClass:"btn btn-sm  btn-success",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setUserCompany(userCompany,'assignPermissions')}}},[_c('i',{staticClass:"fa-solid fa-plus"})]),(userCompany.permissions.length > 0)?_c('div',_vm._l((userCompany.permissions),function(permission){return _c('div',{key:permission.id,staticClass:"text-success"},[_vm._v(" "+_vm._s(permission.description)+" "),_c('a',{staticStyle:{"color":"red"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deletPermission({permission_id: permission.id, userCompanyId:userCompany.id})}}},[_c('i',{staticClass:"fa-solid fa-trash-can"})])])}),0):_c('div',[_vm._v(" No tiene permisos en esta sede ")])])],1)],1)})}},{key:"roles",fn:function(ref){
            var row = ref.row;
return _vm._l((row.user_companies),function(userCompany){return _c('div',{key:userCompany.id},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse_roles'+row.id+userCompany.id),expression:"'collapse_roles'+row.id+userCompany.id"}]},[_vm._v(_vm._s(userCompany.company.name)+" "),_c('i',{staticClass:"fa-solid fa-eye"})]),_c('b-collapse',{attrs:{"id":'collapse_roles'+row.id+userCompany.id}},[_c('a',{staticClass:"btn btn-sm btn-success mt-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setUserCompany(userCompany,'assignRoles')}}},[_c('i',{staticClass:"fa-solid fa-plus"})]),_vm._l((userCompany.roles),function(role){return _c('div',{key:role.id},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse'+row.id+role.id+userCompany.id),expression:"'collapse'+row.id+role.id+userCompany.id"}]},[_vm._v(" "+_vm._s(role.name)+" "),_c('a',{staticStyle:{"color":"red"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteRole({role_id: role.id, userCompanyId:userCompany.id})}}},[_c('i',{staticClass:"fa-solid fa-trash-can"})])]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":'collapse'+row.id+role.id+userCompany.id}},[(role.permissions.length > 0)?_c('b-card',_vm._l((role.permissions),function(rolePer){return _c('div',{key:rolePer.id,staticClass:"text-success"},[_vm._v(" "+_vm._s(rolePer.description)+" ")])}),0):_c('b-card',[_c('div',[_vm._v(" El rol no tiene permisos asociados ")])])],1)],1)})],2)],1)})}},{key:"opciones",fn:function(ref){
            var row = ref.row;
return [_c('div',[_c('button',{directives:[{name:"can",rawName:"v-can",value:({permissions: ['add_company'], doc: 'Agragar empresa a un usuario.'}),expression:"{permissions: ['add_company'], doc: 'Agragar empresa a un usuario.'}"}],staticClass:"btn btn-sm btn-dark",on:{"click":function($event){$event.preventDefault();return _vm.showModalAdd(row)}}},[_vm._v("Agregar empresa")]),_c('button',{directives:[{name:"can",rawName:"v-can",value:({permissions: ['change_password'], doc: 'Cambiar contraseña de un usuario.'}),expression:"{permissions: ['change_password'], doc: 'Cambiar contraseña de un usuario.'}"}],staticClass:"btn btn-sm btn-success",on:{"click":function($event){$event.preventDefault();return _vm.showModalAdd(row, 'ChangePassword')}}},[_vm._v("Editar")])])]}}])})],1)]),_c('AssignPermissionsByCompany',{attrs:{"userCompany":_vm.userCompany},on:{"successAssignPermmisions":_vm.successAssignPermmisions}}),_c('AssignRolesByCompany',{attrs:{"userCompany":_vm.userCompany},on:{"successAssignRoles":_vm.successAssignRoles}}),_c('AddCompany',{attrs:{"user":_vm.user},on:{"finish":function($event){return _vm.refreshPageTable('users_table')}}}),_c('ChangePassword',{attrs:{"user":_vm.user},on:{"finish":function($event){return _vm.refreshPageTable('users_table')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }