<template>
    <div>
        <modal :name="nameModal" :clickToClose="false">
            <div tabindex="-1" role="dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Asignar {{ roleText }} ({{ read.id }})</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <b-form-group class="col-md-12">
                            <label for="users" class="form-label">{{ roleText }}</label>
                            <v-select :options="users" :placeholder="roleText" label="name" @search="searchUser" v-model="user"></v-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="handledEventClickAssing">Asignar</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>

import { searchUsers } from "../../../services/user"
import { assignedOperated } from '../../../services/guide'

export default {
    props:{
        permissionName:{
            required:true,
            type:String,
            default(){
                return "read"
            }
        },
        read:{
            type:Object,
        },
        nameModal:{
            required:true,
            type:String,
            default(){
                return "assignedOperated"
            }
        },
        requestFuntion:{
            type:Function,
            async default(user, read){
                console.log(this.type);
                let resp = await assignedOperated({
                    companyId: this.read.company_id,
                    packageId: read.id,
                    operatorId:user.id,
                    type: this.type
                })

                if (resp.code == 200) {
                    this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    html: resp.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
                }else{
                    this.$swal({
                    position: 'top-end',
                    icon: 'warning',
                    html: resp.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                }
                
                this.$emit("asignedEvent")
            }
        },
        roleText:{
            type:String,
            required: true,
            default(){
                return "Analista"
            }
        },
        type:{
            type:Number,
            required: true,
            default(){
                return 1
            }
        }
    },
    data() {
        return {
            users:[],
            user:null
        }
    },
    mounted() {
        
    },
    methods: {
        async searchUser(search, loading){
            if (search.trim() == "") {
                return   
            }
            loading(true)
            let users = await searchUsers({search, permissionName: this.permissionName, type:this.type, companyId: this.read.company_id})
            this.users = users
            loading(false)
        },
        handledEventClickAssing(){
            this.requestFuntion(this.user, this.read)
        },
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        }
    }
}
</script>