<template>
    <div id="content" class="app-content">
        <div class="card">
            <div class="card-body">
                <h3>Ver roles</h3>
                <button v-can="{permissions: ['create_role'], doc: 'Crear role.'}" class="btn btn-sm btn-dark" @click.prevent="showModalAdd" >Crear rol</button>
                <v-server-table  :columns="columns" ref="roles_table"  :options="{
                    requestFunction,
                    headings,
                    filterable:false,
                }">
                    <template slot="Permisos" slot-scope="{row}">
                        <span v-for="(permission, index) in row.permissions" :key="index" class="badge text-danger">{{  permission.description }},</span>
                    </template>

                    <template slot="opciones" slot-scope="{row}">
                        <button class="btn btn-sm btn-primary" v-if="row.id != 1" @click.prevent="showEditComponent(row)">Editar</button>
                    </template>

                </v-server-table>
                <CreateRole ref="create_role" @CreateRole="refreshPageTable('roles_table')"/>
                <EditRole ref="edit_role" @editRecord="refreshPageTable('roles_table')"/>
            </div>
        </div>
        
    </div>
</template>


<script>
import { getRoles } from '../../services/role';

import CreateRole from './components/CreateRole'
import EditRole from './components/EditRole'



export default {
    components: {
        CreateRole,
        EditRole
    },
   
    data() {
        return {
            columns: ["name","description", "Permisos", "opciones"],
            headings: {
                name: "Nombre",
                email: "Correo",
                document: "Documento"
            },
            userCompany:null,
            user: null
        }
    },
    methods: {
        refreshPageTable(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].setPage(this.page);
            }
        },
        requestFunction(data){
            return getRoles(data).catch(function (e) {
                console.log('error', e);
            });
        },
        showModal(nameModal){
            this.$modal.show(nameModal);
        },
        
        
        showModalAdd(){
            this.$refs.create_role.show()
        },

        showEditComponent(row){
            this.$refs.edit_role.getPermissions()
            this.$refs.edit_role.show(row)
        }

    }
}
</script>
<style >
    .VueTables__limit-field {
        margin-bottom: 10px !important;
        width: 10% !important;
    }

    .vs__dropdown-menu {
    background: #dfe5fb;
    border: none;
    color: #394066;
    font-variant: small-caps;
    z-index:10000 !important;
    }

    .vm--modal{
        overflow: inherit !important;
    }
        
</style>