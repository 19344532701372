import { post } from "./api";

export const  getCycles = async (data)=>{
    try {
        let route = "cycle/show"
        return await post({route, data})
    } catch (error) {
        console.log("Service getCycles Failed", error);
        throw new Error("Service getCycles Failed")
    }
};