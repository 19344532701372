<template>
	<div class="theme-panel" v-bind:class="{ 'active': expand }">
		<!-- <a href="javascript:;" v-on:click="toggle" class="theme-collapse-btn"><i class="fa fa-cog"></i></a> -->
		<div class="theme-panel-content">
			<ul class="theme-list clearfix">
				<li v-bind:class="{ 'active': activeClass == 'theme-red' }"><a href="javascript:;" class="bg-red" v-on:click="toggleTheme('theme-red')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-pink' }"><a href="javascript:;" class="bg-pink" v-on:click="toggleTheme('theme-pink')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-orange' }"><a href="javascript:;" class="bg-orange" v-on:click="toggleTheme('theme-orange')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-yellow' }"><a href="javascript:;" class="bg-yellow" v-on:click="toggleTheme('theme-yellow')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-lime' }"><a href="javascript:;" class="bg-lime" v-on:click="toggleTheme('theme-lime')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-green' }"><a href="javascript:;" class="bg-green" v-on:click="toggleTheme('theme-green')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-teal' }"><a href="javascript:;" class="bg-teal" v-on:click="toggleTheme('theme-teal')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-cyan' }"><a href="javascript:;" class="bg-cyan" v-on:click="toggleTheme('theme-cyan')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-blue' }"><a href="javascript:;" class="bg-blue" v-on:click="toggleTheme('theme-blue')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-purple' }"><a href="javascript:;" class="bg-purple" v-on:click="toggleTheme('theme-purple')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-indigo' }"><a href="javascript:;" class="bg-indigo" v-on:click="toggleTheme('theme-indigo')">&nbsp;</a></li>
				<li v-bind:class="{ 'active': activeClass == 'theme-gray-600' }"><a href="javascript:;" class="bg-gray-600" v-on:click="toggleTheme('theme-gray-600')">&nbsp;</a></li>
			</ul>
			<hr class="mb-0" />
			<div class="row mt-10px pt-3px">
				<div class="col-9 control-label text-dark fw-bold">
					<div>Dark Mode <span class="badge bg-primary ms-1 position-relative py-4px px-6px" style="top: -1px">NEW</span></div>
					<div class="lh-14 fs-13px">
						<small class="text-dark opacity-50">
							Adjust the appearance to reduce glare and give your eyes a break.
						</small>
					</div>
				</div>
				<div class="col-3 d-flex">
					<div class="form-check form-switch ms-auto mb-0 mt-2px">
						<input type="checkbox" class="form-check-input" name="app-theme-dark-mode" v-model="darkMode" v-on:change="toggleDarkMode()" id="appThemeDarkMode" value="1" />
						<label class="form-check-label" for="appThemeDarkMode">&nbsp;</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'ThemePanel',
  data() {
		return {
			expand: false,
			darkMode: false,
			activeClass: 'theme-blue'
		}
  },
	methods: {
		toggle() {
			this.expand = !this.expand;
		},
		toggleTheme(themeClass) {
			var elm = document.getElementsByTagName('body')[0];
			for (var x = 0; x < document.body.classList.length; x++) {
				var targetClass = document.body.classList[x];
				if (targetClass.search('theme-') > -1) {
					elm.classList.remove(targetClass);
				}
			}
		
			elm.classList.add(themeClass);
			this.activeClass = themeClass;
		},
		toggleDarkMode() {
			var elm = document.getElementsByTagName('html')[0];
			
			if (this.darkMode) {
				elm.classList.add('dark-mode');
			} else {
				elm.classList.remove('dark-mode');
			}
		}
	}
}
</script>
