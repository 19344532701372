<template>
    <div id="content" class="app-content">

        <form class="card" @submit.prevent="register">
            <div class="card-body">
                <div class="row">
                    <b-form-group class="col-md-6">
                        <label for="name" class="form-label">Nombres</label>
                        <b-form-input type="text" id="name" placeholder="Nombre" required v-model="userRegister.name"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-6">
                        <label for="surname" class="form-label">Apellidos</label>
                        <b-form-input type="text" id="surname" placeholder="Apellidos" required v-model="userRegister.surname"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-6">
                        <label for="email" class="form-label">Email</label>
                        <b-form-input type="email" id="email" placeholder="Email" required v-model="userRegister.email"></b-form-input>
                    </b-form-group>


                    <b-form-group class="col-md-6">
                        <label for="document" class="form-label">Documento</label>
                        <b-form-input type="number" id="document" placeholder="Documento" required v-model="userRegister.document"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-6">
                        <label for="password" class="form-label">Contraseña</label>
                        <b-form-input type="password" id="password" placeholder="Contraseña" required v-model="userRegister.password"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-6">
                        <label for="companies" class="form-label">Companías</label>
                        <v-select :options="companies" placeholder="Compañías" label="name" multiple v-model="userRegister.companies"></v-select>
                    </b-form-group>

                    <b-form-group class="col-md-6">
                        <label for="roles" class="form-label">Roles</label>
                        <v-select :options="roles" placeholder="Roles" label="name" multiple v-model="userRegister.roles"></v-select>
                    </b-form-group>

                    <b-form-group class="col-md-6">
                        <label for="permisions" class="form-label">Permisos</label>
                        <v-select :options="permissions" placeholder="Permisos" label="name" multiple></v-select>
                    </b-form-group>

                    <div class="justify-content-center mt-4">
                        <button class="btn btn-primary btn-block" type="submit">Crear</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { getPermissions, getRoles } from '../../services/auth'
import { register } from '../../services/user'
import { getCompanies } from '../../services/company'
export default {
    data(){
        return {
            userRegister:{},
            companies: [],
            roles: [],
            permissions: [],
        }
    },

    mounted(){
        this.getCompanies()
        this.getPermissions()
        this.getRoles()
    },
    methods:{
        async register(){

            if (!this.userRegister.companies || this.userRegister.companies.length == 0) {
                alert("Debe seleccionar al menos una empresa.")
                return
            }

            let loader = this.$loading.show({
                container: this.$refs.loginRef
            });

            let data = {
                ...this.userRegister,
                idsCompanies: this.userRegister.companies.map(company => company.id),
                idsRoles: this.userRegister.roles ? this.userRegister.roles.map(role => role.id) : [],
                idsPermissions: this.userRegister.permissions ? this.userRegister.permissions.map(permission => permission.id) : [],
            }

            let resp = await register(data)


            if (resp.code == 200) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    html: resp.message,
                    showConfirmButton: false,
                    timer: 1500
                })

                this.$router.push({ name: "user-view"})
                
            }else{
               this.$swal({
                    position: 'center',
                    icon: 'error',
                    html: resp.message,
                    showConfirmButton: false,
                }) 
            }
            loader.hide()

            
            // console.log(this.userRegister, resp);
        },
        async getCompanies(){
            let resp = await getCompanies()
            this.companies = resp.data
            console.log(resp);
            
        },
        async getPermissions(){
            let resp = await getPermissions()
            this.permissions = resp.data
            console.log(resp);
        },
        async getRoles(){
            let resp = await getRoles()
            this.roles = resp.data
            console.log(resp);
        }
    }
}
</script>