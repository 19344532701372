<template>
	<div id="content" class="app-content">
		<h1 class="page-header mb-3">
			<!-- Hola. <small>esto es lo que está pasando con su tienda hoy.</small>  -->
		</h1>

		<b-row>

			<b-col class="mb-3">
				<b-card class="h-100">
					<div class="d-flex align-items-center mb-2">
						<div class="flex-grow-1">
							<h5 class="mb-1">Informe</h5>
							<div class="fs-13px">Informe de guías</div>
                            <Filters  @handlerFilter="handlerFilter" :searchClinet="false" class="m-2"/>
						</div>
						<!-- <a href="#">See All</a> -->
					</div>
					
					<div class="table-responsive mb-n2">
						<table class="table table-borderless mb-0">
							<thead>
								<tr class="text-dark">
									<th class="ps-0">No</th>
									<th>Lector</th>
									<th class="text-center">Asignadas</th>
									<th class="text-end pe-0">Confirmadas</th>
								</tr>
							</thead>
							<tbody >
								<tr v-for="(user, index) in inform" v-bind:key="index">
									<td class="ps-0">{{ index + 1 }}.</td>
									<td>
										<div class="d-flex align-items-center">
											<!-- <div class="w-40px h-40px">
												<img v-bind:src="transaction.icon" alt="" class="mw-100 mh-100" />
											</div> -->
											<div class="ms-3 flex-grow-1">
												<div class="fw-600 text-dark">{{ user.owner }}</div>
												<!-- <div class="fs-13px">{{ transaction.time }}</div> -->
											</div>
										</div>
									</td>
									<td class="text-center">{{ user.count_records }}</td>
									<td class="text-end pe-0">{{ user.confirmed }}</td>
								</tr>
							</tbody>
							<!-- <tbody v-else>
								<tr>
									<td colspan="4">No records found</td>
								</tr>
							</tbody> -->
						</table>
					</div>
					<!-- END table-responsive -->
				</b-card>
				<!-- END card -->
			</b-col>
			<!-- END col-6 -->
		</b-row>
		<!-- END row -->
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Filters from '../pages/Guide/Components/Filters'
import { getInform } from '../services/guide'
export default {
	components: {
		Filters
	},
	name: 'dashboard',
	data() {
		return {
			bestSellerProduct: [{
				id: 1,
				name: 'iPhone 11 Pro Max (256GB)',
				price: '1,099',
				sales: 382,
				image: '/assets/img/product/product-1.jpg'
			},{
				id: 2,
				name: 'Macbook Pro 13 inch (2020)',
				price: '1,120',
				sales: 102,
				image: '/assets/img/product/product-2.jpg'
			},{
				id: 3,
				name: 'Apple Watch Series 4(2020)',
				price: '349',
				sales: 75,
				image: '/assets/img/product/product-3.jpg'
			},{
				id: 4,
				name: '12.9-inch iPad Pro (256GB)',
				price: '1,099',
				sales: 62,
				image: '/assets/img/product/product-4.jpg'
			},{
				id: 5,
				name: 'iPhone 11 (128gb)',
				price: '799',
				sales: 59,
				image: '/assets/img/product/product-5.jpg'
			}],
			transactionData: [{
				id: 1,
				name: 'Macbook Pro 15 inch',
				time: '5 minutes ago',
				price: '1,699.00',
				icon: '/assets/img/icon/paypal2.svg',
				label: 'Success',
				labelClass: 'badge bg-success bg-opacity-20 text-success'
			},{
				id: 2,
				name: 'Apple Watch 5 Series',
				time: '5 minutes ago',
				price: '699.00',
				icon: '/assets/img/icon/mastercard.svg',
				label: 'Success',
				labelClass: 'badge bg-success bg-opacity-20 text-success'
			},{
				id: 3,
				name: 'iPhone 11 Pro Max',
				time: '12 minutes ago',
				price: '1,299.00',
				icon: '/assets/img/icon/visa.svg',
				label: 'Pending',
				labelClass: 'badge bg-warning bg-opacity-20 text-warning'
			},{
				id: 4,
				name: 'Apple Magic Keyboard',
				time: '15 minutes ago',
				price: '199.00',
				icon: '/assets/img/icon/paypal2.svg',
				label: 'Cancelled',
				labelClass: 'badge text-dark text-opacity-50 bg-dark bg-opacity-10'
			},{
				id: 5,
				name: 'iPad Pro 12.9 inch',
				time: '15 minutes ago',
				price: '1,099.00',
				icon: '/assets/img/icon/mastercard.svg',
				label: 'Cancelled',
				labelClass: 'badge bg-success bg-opacity-20 text-success'
			}],
			chartData: {
				series: [{
					data: [
						8107, 8128, 8122, 8165, 8340, 8423, 8423, 8514, 8481, 8487, 
						8506, 8626, 8668, 8602, 8607, 8512, 8496, 8600, 8881, 9340
					]
				}],
				options: {
					labels: [
						'13 Nov 2020', '14 Nov 2020', '15 Nov 2020', '16 Nov 2020',
						'17 Nov 2020', '20 Nov 2020', '21 Nov 2020', '22 Nov 2020',
						'23 Nov 2020', '24 Nov 2020', '27 Nov 2020', '28 Nov 2020',
						'29 Nov 2020', '30 Nov 2020', '01 Dec 2020', '04 Dec 2020', 
						'05 Dec 2020', '06 Dec 2020', '07 Dec 2020', '08 Dec 2020'
					],
					colors: ['#1f6bff'],
					chart: {
						height: 256,
						type: 'line',
						toolbar: {
							show: false
						}
					},
					annotations: {
						yaxis: [{
							y: 8200,
							borderColor: '#640DF3',
							label: {
								borderColor: '#640DF3',
								style: {
									color: '#ffffff',
									background: '#640DF3',
								},
								text: 'Support',
							}
						}, {
							y: 8600,
							y2: 9000,
							borderColor: '#FF9500',
							fillColor: '#FF9500',
							opacity: 0.1,
							label: {
								borderColor: '#FFCC00',
								style: {
									fontSize: '10px',
									color: '#212837',
									background: '#FFCC00',
								},
								text: 'Earning',
							}
						}],
						xaxis: [{
							x: new Date('23 Nov 2020').getTime(),
							strokeDashArray: 0,
							borderColor: '#212837',
							label: {
								borderColor: '#212837',
								style: {
									color: '#ffffff',
									background: '#212837',
								},
								text: 'Anno Test',
							}
						}, {
							x: new Date('26 Nov 2020').getTime(),
							x2: new Date('28 Nov 2020').getTime(),
							fillColor: '#0CD095',
							opacity: 0.4,
							label: {
								borderColor: '#0CD095',
								style: {
									fontSize: '10px',
									color: '#fff',
									background: '#0CD095',
								},
								offsetY: -7,
								text: 'X-axis range',
							}
						}],
						points: [{
							x: new Date('01 Dec 2020').getTime(),
							y: 8607.55,
							marker: {
								size: 8,
								fillColor: '#ffffff',
								strokeColor: '#FF2D55',
								radius: 2
							},
							label: {
								borderColor: '#FF2D55',
								offsetY: 0,
								style: {
									color: '#ffffff',
									background: '#FF2D55',
								},

								text: 'Point Annotation',
							}
						}]
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						curve: 'straight'
					},
					grid: {
						padding: {
							right: 30,
							left: 0
						},
						borderColor: 'rgba('+ window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb') + ', .15)'
					},
					xaxis: {
						type: 'datetime',
					},
				}
			},
			inform:[],
			filter: {
                p_cycle_id: "",
                users: "",
                p_company_id: "",
                p_status_id: "",
                p_date: ""
            }
		}
	},
	async mounted(){
		await this.getUserInfo()
		this.filter.p_company_id = `${this.userInfo.user.user_companies[0].company_id}`
		this.fetchInform()
		
	},
	methods: {
		...mapActions(['getUserInfo']),
		handlerFilter(filter){
			this.filter = filter
			this.fetchInform()
		},

		async fetchInform(){
			let loader = this.$loading.show();
			let resp = await getInform(this.filter)
			if(resp.code == 200){
				this.inform = resp.data
			}
			loader.hide()
		}
	},
	computed: {
        ...mapState(['userInfo'])
    },
}
</script>