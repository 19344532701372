<template>
    <div id="content" class="app-content">
        <div class="card">
            <div class="card-body">
                <h3>Lista de archivos procesados</h3>
                <div class="row justify-content-center">
                    <FiltersScheduling @handlerFilter="handlerFilter"/>
                </div>
                <v-server-table :columns="columns" :class="['table-sm']" :ref="ref_table" @pagination="handledPagination"  :options="{
                    requestFunction,
                    headings,
                    filterable:false,
                    filterByColumn: false,
                    sortable: [],
                }">
                    <template slot="status.name" slot-scope="{row}">
                        <div class="rounded p-1 text-white" :style="{background: row.status.color, textAlign: 'center'}">
                            <span >{{ row.status.name }}</span>
                        </div>
                    </template>

                    <template slot="Opciones" slot-scope="{row}">
                        <a 
                            v-can="{permissions:['cambiar_estado_masivo_de_guias_a_partir_de_un_archivo_cargado_previamente'], doc: 'Cambiar estado de guias apartir de un archivo ya cargado'}"
                            class="btn btn-primary btn-sm" 
                            href="#" 
                            @click.prevent="handlerEdit(row)" 
                        >
                            <i class="far fa-edit"></i>
                        </a>
                    </template>
                </v-server-table>
            </div>
        </div>
        <ChangeState ref="modalState" @handlerChangeState="handlerChangeState" />
        
    </div>
</template>
<script>
import { listScheduling } from '../../services/scheduling';
import ChangeState from './Components/ChangeState.vue';
import FiltersScheduling from './Components/Filter.vue'
    export default {
        components: {
            ChangeState,FiltersScheduling
        },
        data(){
            return {
                ref_table:'scheduling_table',
                pagee:1,
                columns:[
                    'id',
                    'user.name',
                    'file_name',
                    'status.name',
                    'processed_quantity',
                    'processed_at',
                    'format_created_at',
                    'create_packages_count',
                    'Opciones',
                ],
                headings:{
                    id:'ID',
                    'user.name':'Cargado por',
                    file_name:'Archivo',
                    'status.name':'Estado de procesamineto',
                    processed_quantity:'Procesadas',
                    processed_at:'Fecha de procesamiento',

                    format_created_at:'Fecha de creación',
                    create_packages_count: "Paquetes en estado creado"
                },
                filters:null,
            }
        },
        methods:{
            requestFunction(data){
                data = {
                    ...data,
                    filters:this.filters
                }
                return listScheduling(data, this).catch(function (e) {
                    console.log('error', e);
                });
            },
            handledPagination(page){
                this.page = page;
            },
            refreshPageTable(ref) {
                if (this.$refs[ref]) {
                    this.$refs[ref].setPage(this.page);
                }
            },
            handlerEdit(row){
                this.$refs.modalState.init(row)
                this.$refs.modalState.show()
            },
            handlerChangeState(){
                this.refreshPageTable(this.ref_table)
            },
            handlerFilter(filters){
                this.filters = filters
                this.refreshPageTable(this.ref_table)
            }
        }
    }
</script>