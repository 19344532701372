var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content",attrs:{"id":"content"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v("Ver roles")]),_c('button',{directives:[{name:"can",rawName:"v-can",value:({permissions: ['create_role'], doc: 'Crear role.'}),expression:"{permissions: ['create_role'], doc: 'Crear role.'}"}],staticClass:"btn btn-sm btn-dark",on:{"click":function($event){$event.preventDefault();return _vm.showModalAdd.apply(null, arguments)}}},[_vm._v("Crear rol")]),_c('v-server-table',{ref:"roles_table",attrs:{"columns":_vm.columns,"options":{
                requestFunction: _vm.requestFunction,
                headings: _vm.headings,
                filterable:false,
            }},scopedSlots:_vm._u([{key:"Permisos",fn:function(ref){
            var row = ref.row;
return _vm._l((row.permissions),function(permission,index){return _c('span',{key:index,staticClass:"badge text-danger"},[_vm._v(_vm._s(permission.description)+",")])})}},{key:"opciones",fn:function(ref){
            var row = ref.row;
return [(row.id != 1)?_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.showEditComponent(row)}}},[_vm._v("Editar")]):_vm._e()]}}])}),_c('CreateRole',{ref:"create_role",on:{"CreateRole":function($event){return _vm.refreshPageTable('roles_table')}}}),_c('EditRole',{ref:"edit_role",on:{"editRecord":function($event){return _vm.refreshPageTable('roles_table')}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }