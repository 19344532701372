<template>
    <div id="content" class="app-content">
        <div class="card">
            <div class="card-body">
                <h3>Guias</h3>
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <b-button-group>
                            <Filters @handlerFilter="handlerFilter" class="m-2"/>
                            <AssignedMassive class="m-2" :filters="filters" v-can="{permissions: ['assigned_masive_to_guides'], doc: 'Asignación masiva de guías.'}" @emitAssignedMassive="handlerEmitAssignedMassive"/>
                            <SchedulingUploadFile class="m-2" :action_id="2" button_title="Cargar Guias" @complete="refreshPageTable('reads_table')"/>
                            <a class="btn btn-success m-2" href="#" @click.prevent="handlerDownloadExcel" v-can="{permissions: ['download_excel_with_guides'], doc: 'Descargar excel con guias.'}">Excel <i class="fa-regular fa-file-excel"></i></a>
                            <DownloadExcelMassive :filters="filters"/>
                            <a class="btn btn-danger m-2" href="#" @click.prevent="redirect(`/guide/view-pdf/null`, true)" target="_blank" disabled>Pdf<i class="fa-regular fa-file-pdf"></i></a>
                        </b-button-group>
                    </div>
                </div>
                <v-server-table :columns="columns" :class="['table-sm']" ref="reads_table" @pagination="handledPagination"  :options="{
                    requestFunction,
                    headings,
                    filterable:false,
                    filterByColumn: false,
                    sortable: [],
                }">

                    <template slot="id" slot-scope="{row}">
                        <div >
                            <span>{{ row.id }}</span>
                            <br>
                            <span v-if="row.is_package" class="text-muted" style="font-size:10px">{{ row.package_code }}</span>
                        </div>
                    </template>

                    <template slot="operator_name" slot-scope="{row}">
                        <div >
                            <span class="text-success" v-if="row.operator_id">
                                {{ row.operator_name }}
                                <a href="#" @click="handlerUnassign(row)" v-if="row.status_id != 4" v-can="{permissions: ['unassign_operated_guide'], doc: 'Desasignar lector.'}"> <i class="fas fa-trash-can text-danger" ></i></a> 
                            </span>
                            <a href="#" v-if="!row.operator_id && row.status_id != 4" @click.prevent="show(row, nameModalOperated)" v-can="{permissions: ['assigned_operated_guide'], doc: 'Asignar lector.'}"> <i class="fas fa-edit"></i></a> 
                        </div>
                    </template>

                    <template slot="client_name" slot-scope="{row}">
                            <span >{{ row.client_name }}</span>
                    </template>

                    <template slot="must_be_confirmed" slot-scope="{row}">
                            <span class="text-primary" v-if="row.must_be_confirmed">SI</span>
                            <span class="text-danger" v-else>NO</span>

                    </template>

                    <template slot="status_name" slot-scope="{row}">
                        <div class="rounded p-1" :style="{background: row.status_color, textAlign: 'center'}">
                            <span >{{ row.status_name }}</span>
                        </div>
                    </template>

                    <template slot="confirmed_at" slot-scope="{row}">
                        <p class="text-muted text-center" style="font-size:10px">{{ row.confirmed_at }}</p>
                    </template>

                    <template slot="url" slot-scope="{row}">
                        <div v-if="row.url">
                            <a :href="basePath+'/storage/'+row.url" target="_blank">
                                <img style="width:40px;height: 40px;" :src="basePath+'/storage/'+row.url" :alt="basePath+'/storage/'+row.url">
                            </a>
                        </div>
                    </template>

                    <template slot="scheduling_id" slot-scope="{row}">
                        <div :id="`scheduling_id-${row.id}`">
                            {{ tooltip(`scheduling_id-${row.id}`) }}
                            {{ row.scheduling_id }}
                        </div>
                    </template>

                    

                     

                    <template slot="confirmation_distance" slot-scope="{row}">
                        <div class="text-center">
                            <span class="text-muted" v-if="row.confirmation_distance">{{ `${row.confirmation_distance} KM` }}</span>
                        </div>
                    </template>

                    <template slot="department_name" slot-scope="{row}">
                        <div class="text-center">
                            <span >{{ `${row.department_name},  ${row.city_name}` }}</span>
                        </div>
                    </template>


                    <template slot="Opciones" slot-scope="{row}">
                        <a class="btn btn-danger btn-sm" href="#" @click.prevent="redirect(`/guide/view-pdf/${row.id}`)" target="_blank">Pdf<i class="fa-regular fa-file-pdf"></i></a>
                    </template>
                </v-server-table>
            </div>
        </div>
        <AssignedOperated
            :nameModal="nameModalOperated"
            :read="read"
            :permissionName="permissionNameOperated"
            @asignedEvent="handledAsignedEvent"
            roleText="Lector"
            :type=1
        />
    </div>

</template>


<script>
import { downloadGuides, getGuides, unAssignOperated } from '../../services/guide'
import AssignedOperated from './Components/AssignedOperated'
import Filters from './Components/Filters'
import AssignedMassive from './Components/AssignedMassive'
import SchedulingUploadFile from './Components/SchedulingUploadFile'
import DownloadExcelMassive from './Components/DownloadExcelMassive.vue'
import {basePath} from '../../services/api'
import { mapState } from 'vuex'
import tippy from 'tippy.js';
export default {
    components: {
        AssignedOperated,
        Filters,
        AssignedMassive,
        SchedulingUploadFile,
        DownloadExcelMassive
    },
    data(){
        return {
            page:1,
            read:{},
            filters:null,
            permissionNameAnalyst:"analyst",
            permissionNameOperated:"read",
            nameModalOperated:"assignedOperated",
            nameModalAnalyst:"assignedAnalyst",
            headings: {
                client_name: "Cliente",
                address: "Dirección",
                confirmation_distance: "Distancia",
                status_name: "estado",
                package_code: "Código",
                department_name: "Ciudad",
                operator_name: "Lector",
                company_name: 'Empresa',
                date_issue: 'Fecha de admitido',
                cycle: 'Ciclo',
                must_be_confirmed: "Confirmar con foto ?",
                confirmed_at: 'Confirmación',
                comment: 'Comentarios',
                url: 'Foto',
                consecutive: "id (Consecutivo)",
                scheduling_id: "Tarea",
                code_account: "Cliente (NUM_CUENTA)"
            },
            columns: [
                'consecutive',
                'scheduling_id',
                'company_name',
                'date_issue',
                'cycle',
                'code_account',
                'client_name',
                'address',
                'status_name',
                'department_name',
                'operator_name',
                'must_be_confirmed',
                'confirmed_at',
                'comment',
                'url',
                'confirmation_distance',
                'Opciones'
            ],
            basePath
        }
    },
    mounted() {
        // window.tableGuides = this.$refs.reads_table
        this.tooltip()
        document.getElementsByClassName("VueTables__table")[0].className += ' table-sm'
    },
    methods: {
        tooltip(el = "scheduling_id", message = "Tarea asociada al paquete."){
            tippy(el, message)
        },
        assing(row){
            alert(JSON.stringify(row))
        },
        requestFunction(data){
            data = {
                ...data, filters:this.filters
            }
            return getGuides(data, this).catch(function (e) {
                console.log('error', e);
            });
        },
        show(read, nameModal){
            this.read = read
            this.$modal.show(nameModal)
        },

        hide(){
            this.read = {}
            this.$modal.hide(this.nameModalOperated)
            this.$modal.hide(this.nameModalAnalyst)
        },

        handledAsignedEvent(){
            this.hide()
            this.refreshPageTable("reads_table")
        },

        refreshPageTable(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].setPage(this.page);
            }
        },
        handledPagination(page){
            this.page = page;
        },
        handlerFilter(data){
            this.filters = data
            this.refreshPageTable("reads_table")
        },
        handlerEmitAssignedMassive(){
            this.refreshPageTable("reads_table")
        },

        handlerUnassign(guide){
            this.$swal.fire({
                html: `Seguro que quieres desasignar el lector <b>(${guide.operator_name})</b>`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'SI',
                cancelButtonText: `Cancelar`,
                cancelButtonColor: '#e6180d',
                confirmButtonColor: '#1f6bff'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let loading = this.$loading.show();
                        let resp = await unAssignOperated({packageId: guide.id})
                        loading.hide()
                        if (resp.code == 200) {
                            this.$swal.fire({html:resp.message, icon:'success'})
                            this.refreshPageTable("reads_table")
                        }
                    } 
                })
        },

        async handlerDownloadExcel(){
            let filters = {
                ...this.filters
            }
            if (!filters.p_company_id) {
                filters.p_company_id = this.userInfo.user.user_companies.map(item => item.company_id).join();
            }
            let loading = this.$loading.show();
            let blob = await downloadGuides({filters})
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            loading.hide()
            a.download = "guides.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again  
        },
        goTo(routeName){
            this.$router.push({ name: routeName, params: this.filters  })
        },
        redirect(name, validateFilter = false){
            if(validateFilter && !this.filters){
                this.$swal({
                    position: 'center',
                    icon: 'warning',
                    html: 'Debe hacer previamente un filtro',
                    showConfirmButton: false,
                    timer: 1500
                })
                return
            }

            return window.open(name, '_blank');
        }
        
    },
    computed:{
        ...mapState(['userInfo'])
    }
}
</script>


<style>
    .custom-progress p{
        color: red
    }
</style>