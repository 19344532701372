<template>
    <div>
        <modal :name="nameModal" :clickToClose="false" height="auto">
            <div :ref="nameModal" tabindex="-1" role="dialog" >

                <div class="modal-content">
                    <h4 class="text-center">Editar ({{ roleName }})</h4>
                    <div class="modal-body">
                        <b-form-group class="col-md-12">
                            <label for="role" class="form-label">Rol</label>
                            <input type="text" class="form-control" placeholder="Rol" v-model="roleName">
                        </b-form-group>
                        <b-form-group class="col-md-12">
                            <label for="permissions" class="form-label">Permisos</label>
                            <v-select :options="permissions" placeholder="Permisos" multiple label="description" v-model="permissionsSend"></v-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click.prevent="editRole">Editar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import { getPermissions } from '../../../services/permission';
import { editRole } from '../../../services/role';
export default {
    data(){
        return {
            nameModal:"editRole",
            permissions:[],
            permissionsSend:[],
            roleName:null,
            roleId:null
        }
    },

    methods:{
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(role = {}){
            this.permissionsSend = role.permissions
            this.roleName = role.name
            this.roleId = role.id
            this.$modal.show(this.nameModal);
        },

        async getPermissions(){
            if (this.permissions.length > 0) {
                return
            }
            let resp = await getPermissions()
            if (resp.code == 200) {
                this.permissions = resp.data
                return
            }
            alert(resp.message)
        },
        async editRole(){
            try {
                this.validate()
                
                let resp = await editRole({
                    "companyId": this.userInfo.user.user_companies[0].company_id,
                    "name": this.roleName,
                    "permissions": this.permissionsSend.map(item => item.id),
                    "roleName": this.roleName,
                    "roleId": this.roleId
                });

                if (resp.code == 200) {
                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        html: resp.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.hide()
                    this.$emit("editRecord")
                }else{
                    this.$swal({
                        position: 'center',
                        icon: 'warning',
                        html: 'Quizas este rol ya exista.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$emit("errorEditRecord", resp)
                }
                
            } catch (error) {
                this.$swal({
                    position: 'center',
                    icon: 'warning',
                    html: error.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        validate(){
            if (!this.roleName || this.roleName == "") {
                throw new Error("Debe indicar un nombre para este rol.")
            }

            if (this.permissionsSend.length == 0) {
                throw new Error("Debe indicar por lo menos un permiso.")
            }
        }      
    },
    computed: {
        ...mapState(['userInfo'])
    },
}
</script>