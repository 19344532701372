<template>
    <div>
        <modal :name="nameModal" :clickToClose="false" height="auto">
            <div :ref="nameModal" tabindex="-1" role="dialog" >
                <div class="modal-content">
                    <div class="modal-body">
                        <b-form-group class="col-md-12">
                            <label for="role" class="form-label">Rol</label>
                            <input type="text" class="form-control" placeholder="Rol" v-model="roleName">
                        </b-form-group>
                        <b-form-group class="col-md-12">
                            <label for="permissions" class="form-label">Permisos</label>
                            <v-select :options="permissions" placeholder="Permisos" multiple label="description" v-model="permissionsSend"></v-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click.prevent="createRole">Crear</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import { getPermissions } from '../../../services/permission';
import { addPermissions, createRole } from '../../../services/role';
export default {
    
    data(){
        return {
            nameModal:"createRole",
            permissions:[],
            permissionsSend:[],
            roleName:null
        }
    },
    mounted() {
        this.getPermissions()
    },
    methods:{
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },

        async getPermissions(){
            let resp = await getPermissions()
            if (resp.code == 200) {
                this.permissions = resp.data
                return
            }
            alert(resp.message)
        },
        async createRole(){
            try {
                this.validate()
                
                let resp = await createRole({
                    "companyId": this.userInfo.user.user_companies[0].company_id,
                    "name": this.roleName,
                    "description": this.roleName
                });

                if (resp.code == 200) {
                    let resp2 = await addPermissions({
                        "companyId": 1,
                        "roleId":resp.data.id,
                        "permissions": this.permissionsSend.map(item => item.id)
                    })

                    if (resp2.code == 200) {
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            html: resp.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.hide()
                        this.$emit("CreateRole")
                    }
                }else{
                    this.$swal({
                        position: 'center',
                        icon: 'warning',
                        html: 'Quizas este rol ya exista.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                
            } catch (error) {
                this.$swal({
                    position: 'center',
                    icon: 'warning',
                    html: error.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        validate(){
            if (!this.roleName || this.roleName == "") {
                throw new Error("Debe indicar un nombre para este rol.")
            }

            if (this.permissionsSend.length == 0) {
                throw new Error("Debe indicar por lo menos un permiso.")
            }
        }      
    },
    computed: {
        ...mapState(['userInfo'])
    },
}
</script>