import { post } from "./api";

export const  getRoles = async (data)=>{
    try {
        let route = "role/show"
        return await post({route:route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};


export const  createRole = async (data)=>{
    try {
        let route = "role/create"
        return await post({route:route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  addPermissions = async (data)=>{
    try {
        let route = "role/add_permissions"
        return await post({route:route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};

export const  editRole = async (data)=>{
    try {
        let route = "role/edit"
        return await post({route:route, data})
    } catch (error) {
        console.log("Service Login Failed", error);
        throw new Error("Service Login Failed")
    }
};



