<template>
    <div>
        <modal :name="nameModal" :clickToClose="false" height="auto">
            <div :ref="nameModal" tabindex="-1" role="dialog" >
                <form class="modal-content" @submit.prevent="changePassword" v-if="user">
                    <h3 class="text-center">
                        {{ userInfo.user.name }}
                    </h3>
                    <div class="modal-body">
                         <b-form-group class="col-md-12">
                            <label for="name" class="form-label">Nombres</label>
                            <b-form-input type="text" id="name" placeholder="Nombre" :value="user.name" disabled></b-form-input>
                        </b-form-group>

                        <b-form-group class="col-md-12">
                        <label for="password" class="form-label">Contraseña</label>
                        <b-form-input type="password" id="password" placeholder="Contraseña" required v-model="password"></b-form-input>
                    </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                        <button type="submit" class="btn btn-primary">Editar</button>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import { changePassword } from '../../../services/user';
export default {
    props:{
        user: {
            required:true,
        }
    },
    data(){
        return {
            nameModal:"ChangePassword",
            password:null,
        }
    },
    mounted() {
    },
    methods:{
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },
        async changePassword(){
            if (!this.password && this.password.length > 5) {
                return
            }

            let resp = await changePassword({
                userId: this.user.id,
                companyId: this.userInfo.user.user_companies[0].company_id,
                newPassword: this.password,
            })

            if (resp.code == 200) {
               this.$swal({
                    position: 'center',
                    icon: 'success',
                    html: resp.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }else{
                this.$swal({
                    position: 'center',
                    icon: 'warning',
                    html: resp.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }

            this.hide()
            this.$emit('finish')

        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
}
</script>