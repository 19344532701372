<template>
	<div class="login">
		<div class="login-content" ref="loginRef">
			<b-alert variant="danger" :show="errorMessages.length ? true : false">
				<ul>
					<li v-for="(item, k) in errorMessages" :key="k">
						{{ item }}
					</li>
				</ul>
			</b-alert>
			<form @submit.prevent="checkForm" name="login_form">
				<h1 class="text-center">Iniciar</h1>
				<div class="text-muted text-center mb-4">
					Para su protección, verifique su identidad.
				</div>
				<div class="mb-3">
					<label class="form-label">Documento</label>
					<b-input size="lg" class="fs-15px" autocomplete="username" placeholder="Documento" v-model="auth.document" type="number"></b-input>
				</div>
				<div class="mb-3">
					<div class="d-flex">
						<label class="form-label">Contraseña</label>
						<!-- <b-link a href="#" class="ms-auto text-muted">Perdió su contraseña?</b-link> -->
					</div>
					<b-input type="password" size="lg" autocomplete="current-password" class="fs-15px" placeholder="Contraseña" v-model="auth.password"></b-input>
				</div>
				<button type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Iniciar sesión</button>
			</form>
		</div>
	</div>
</template>

<script>
	import AppOptions from '../../config/AppOptions.vue'
	import { login } from '../../services/auth'
	import {mapActions} from 'vuex'

	export default {
		data(){
			return {
				auth:{
					document:"",
					password:"",
				},
				errorMessages:[],
				isAuthenticated:false,
			}
		},
		mounted() {
			AppOptions.appWithoutSidebar = true;
			AppOptions.appWithoutHeader = true;
			AppOptions.appContentFullWidth = true;
		},
		beforeRouteLeave (to, from, next) {
			AppOptions.appWithoutSidebar = false;
			AppOptions.appWithoutHeader = false;
			AppOptions.appContentFullWidth = false;
			next();
		},
		methods: {
			...mapActions(["getCompanies","getCycles","getPermissions","getRoles","getUserInfo"]),
			async checkForm() {
				let loader = this.$loading.show({
                    container: this.$refs.loginRef
                });
				try {
					let resp = await login(this.auth)
					if (resp.code == 422) {
						this.errorMessages = []
						for (const key in resp.errors) {
							const element = resp.errors[key];
							this.errorMessages.push(...element)
						}
					}else if (resp.code == 800) {
						this.errorMessages = [resp.error]
					}else if (resp.code == 200){
						this.isAuthenticated = true
					}
					if (this.isAuthenticated) {
						this.setCredentials(resp.token, resp.expiration, resp.user)
						window.location = '/'
						// this.redirectRoute('dashboard')
					}

					loader.hide()
				} catch (error) {
					console.log("checkForm",error);
					loader.hide()
				}
			},
			redirectRoute(routeName){
				this.$router.push({ name: routeName}).catch((err) =>{
					console.log(err);
				})
			},
			setCredentials(token, timeExpiredToken, user){
				localStorage.setItem("token", token)
				localStorage.setItem("timeExpiredToken", timeExpiredToken)
				localStorage.setItem("user", JSON.stringify(user))
			}
		}
	}
</script>