import { post } from "./api";

export const  getCompanies = async (data)=>{
    try {
        let route = "company/show"
        return await post({route, data})
    } catch (error) {
        console.log("Service getCompanies Failed", error);
        throw new Error("Service getCompanies Failed")
    }
};