<template >
    <div>
        <a href="#" class="btn btn-success text-center" @click.prevent="show" >Filtros <i class="fas fa-filter"></i></a>
        <modal :name="this.nameModal" :clickToClose="false" height="auto">
            <div tabindex="-1" role="dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Filtrar</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <b-form-group class="col-md-12">
                            <label for="companies" class="form-label">Empresa</label>
                            <v-select :options="companies" :reduce="item => item.id"  multiple placeholder="Empresa" label="name" v-model="selectedCompanies"></v-select>
                        </b-form-group>

                        <b-form-group class="col-md-12 mt-2">
                            <label for="cycles" class="form-label">Ciclos</label>
                            <v-select :options="cycles" multiple placeholder="Ciclos" :reduce="item => item.id"  label="name" v-model="cycle"></v-select>
                        </b-form-group>

                        <b-form-group class="col-md-12 mt-2">
                            <label for="cycles" class="form-label">Estados</label>
                            <v-select :options="statuses" multiple placeholder="Estados" :reduce="item => item.id"  label="name" v-model="status"></v-select>
                        </b-form-group>

                        <b-form-group class="col-md-12 mt-2">
                            <label for="startDate" class="form-label">Inicio</label>
                            <input type="date" class="form-control" v-model="startDate" name="startDate">
                        </b-form-group>


                        <b-form-group class="col-md-12 mt-2">
                            <label for="finalDate" class="form-label">Fin</label>
                            <input type="date" class="form-control" v-model="finalDate" name="finalDate">
                        </b-form-group>

                        <div class="col-md-12 mt-2 form-group" v-if="searchClinet">
                            <label for="users" class="form-label">Clientes</label>
                            <v-select
                                :options="users" 
                                 
                                placeholder="Clientes" 
                                :reduce="item => item.id" 
                                label="name" 
                                @search="searchUser" 
                                v-model="user"
                                inputId="users"
                            >
                            </v-select>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal" @click.prevent="cleanFilters">Limpiar</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="hide()">Cerrar</button>
                        <button type="button" class="btn btn-primary" @click.prevent="emitFilter" >Filtrar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import { searchClients } from "../../../services/client"
import {mapState,mapActions} from "vuex"

export default {
    props:{
        searchClinet:{
            type: Boolean,
            default(){
                return true
            }
        }
    },
    data() {
        return {
            nameModal:"filterRead",
            cycle:[],
            selectedCompanies:[],
            users:[],
            user:[],
            status:[],
            startDate:null,
            finalDate:null,
            statuses:[
                {
                    id:3,
                    name: 'Creada'
                },
                {
                    id:4,
                    name: 'Confirmada'
                }
            ],
        }
    },
    mounted() {
        if (!this.companies || this.companies.length == 0) {
            this.getCompanies()
        }
        if (!this.cycles || this.cycles.length == 0) {
            this.getCycles()
        }
    },
    methods:{
        ...mapActions(["getCompanies","getCycles"]),
        async searchUser(search, loading){
            if (search.trim() == "") {
                return   
            }
            loading(true)
            this.users = []
            let users = await searchClients({search, permissionName: "analyst", type:this.type})
            this.users = users
            loading(false)
        },
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },
        cleanFilters(){
            this.cycle = []
            this.selectedCompanies = [],
            this.user = [],
            this.$emit("handlerFilter",null)
            this.hide()
        },
        emitFilter(){
            let data ={
                p_cycle_id: this.cycle.join(),
                users: this.user.join(),
                p_company_id: this.selectedCompanies.join(),
                p_status_id: this.status.join(),
                p_start_date: this.startDate,
                p_final_date: this.finalDate
            }
            localStorage.setItem("guideFilter", JSON.stringify(data))
            this.$emit("handlerFilter",data)

            this.hide()
        }
    },
    computed:{
        ...mapState([
            "companies","cycles"
        ])
    }
}
</script>