var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",class:{ 
	'app-sidebar-minified': _vm.appOptions.appSidebarMinified,
	'app-sidebar-mobile-toggled': _vm.appOptions.appSidebarMobileToggled,
	'app-sidebar-mobile-closed': _vm.appOptions.appSidebarMobileClosed,
	'app-content-full-height': _vm.appOptions.appContentFullHeight,
	'app-content-full-width': _vm.appOptions.appContentFullWidth,
	'app-without-sidebar': _vm.appOptions.appWithoutSidebar,
	'pt-0': _vm.appOptions.appWithoutHeader,
	'app-boxed-layout': _vm.appOptions.appBoxedLayout,
	'app-footer-fixed': _vm.appOptions.appWithFooter
},attrs:{"id":"app"}},[(_vm.progress)?_c('b-progress',{attrs:{"max":100,"animated":""}},[_c('b-progress-bar',{attrs:{"value":_vm.progress.value,"variant":"success"}},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.progress.value)+"% / "+_vm._s(_vm.progress.total))])])])],1):_vm._e(),_c('vue-ins-progress-bar'),(!_vm.appOptions.appWithoutHeader)?_c('Header'):_vm._e(),(!_vm.appOptions.appWithoutSidebar)?_c('Sidebar'):_vm._e(),_c('router-view'),(_vm.appOptions.appWithFooter)?_c('Footer'):_vm._e(),_c('ThemePanel')],1)}
var staticRenderFns = []

export { render, staticRenderFns }