var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content",attrs:{"id":"content"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v("Guias")]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-6"},[_c('b-button-group',[_c('Filters',{staticClass:"m-2",on:{"handlerFilter":_vm.handlerFilter}}),_c('AssignedMassive',{directives:[{name:"can",rawName:"v-can",value:({permissions: ['assigned_masive_to_guides'], doc: 'Asignación masiva de guías.'}),expression:"{permissions: ['assigned_masive_to_guides'], doc: 'Asignación masiva de guías.'}"}],staticClass:"m-2",attrs:{"filters":_vm.filters},on:{"emitAssignedMassive":_vm.handlerEmitAssignedMassive}}),_c('SchedulingUploadFile',{staticClass:"m-2",attrs:{"action_id":2,"button_title":"Cargar Guias"},on:{"complete":function($event){return _vm.refreshPageTable('reads_table')}}}),_c('a',{directives:[{name:"can",rawName:"v-can",value:({permissions: ['download_excel_with_guides'], doc: 'Descargar excel con guias.'}),expression:"{permissions: ['download_excel_with_guides'], doc: 'Descargar excel con guias.'}"}],staticClass:"btn btn-success m-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handlerDownloadExcel.apply(null, arguments)}}},[_vm._v("Excel "),_c('i',{staticClass:"fa-regular fa-file-excel"})]),_c('DownloadExcelMassive',{attrs:{"filters":_vm.filters}}),_c('a',{staticClass:"btn btn-danger m-2",attrs:{"href":"#","target":"_blank","disabled":""},on:{"click":function($event){$event.preventDefault();return _vm.redirect("/guide/view-pdf/null", true)}}},[_vm._v("Pdf"),_c('i',{staticClass:"fa-regular fa-file-pdf"})])],1)],1)]),_c('v-server-table',{ref:"reads_table",class:['table-sm'],attrs:{"columns":_vm.columns,"options":{
                requestFunction: _vm.requestFunction,
                headings: _vm.headings,
                filterable:false,
                filterByColumn: false,
                sortable: [],
            }},on:{"pagination":_vm.handledPagination},scopedSlots:_vm._u([{key:"id",fn:function(ref){
            var row = ref.row;
return [_c('div',[_c('span',[_vm._v(_vm._s(row.id))]),_c('br'),(row.is_package)?_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(row.package_code))]):_vm._e()])]}},{key:"operator_name",fn:function(ref){
            var row = ref.row;
return [_c('div',[(row.operator_id)?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(row.operator_name)+" "),(row.status_id != 4)?_c('a',{directives:[{name:"can",rawName:"v-can",value:({permissions: ['unassign_operated_guide'], doc: 'Desasignar lector.'}),expression:"{permissions: ['unassign_operated_guide'], doc: 'Desasignar lector.'}"}],attrs:{"href":"#"},on:{"click":function($event){return _vm.handlerUnassign(row)}}},[_c('i',{staticClass:"fas fa-trash-can text-danger"})]):_vm._e()]):_vm._e(),(!row.operator_id && row.status_id != 4)?_c('a',{directives:[{name:"can",rawName:"v-can",value:({permissions: ['assigned_operated_guide'], doc: 'Asignar lector.'}),expression:"{permissions: ['assigned_operated_guide'], doc: 'Asignar lector.'}"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.show(row, _vm.nameModalOperated)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()])]}},{key:"client_name",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.client_name))])]}},{key:"must_be_confirmed",fn:function(ref){
            var row = ref.row;
return [(row.must_be_confirmed)?_c('span',{staticClass:"text-primary"},[_vm._v("SI")]):_c('span',{staticClass:"text-danger"},[_vm._v("NO")])]}},{key:"status_name",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"rounded p-1",style:({background: row.status_color, textAlign: 'center'})},[_c('span',[_vm._v(_vm._s(row.status_name))])])]}},{key:"confirmed_at",fn:function(ref){
            var row = ref.row;
return [_c('p',{staticClass:"text-muted text-center",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(row.confirmed_at))])]}},{key:"url",fn:function(ref){
            var row = ref.row;
return [(row.url)?_c('div',[_c('a',{attrs:{"href":_vm.basePath+'/storage/'+row.url,"target":"_blank"}},[_c('img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":_vm.basePath+'/storage/'+row.url,"alt":_vm.basePath+'/storage/'+row.url}})])]):_vm._e()]}},{key:"scheduling_id",fn:function(ref){
            var row = ref.row;
return [_c('div',{attrs:{"id":("scheduling_id-" + (row.id))}},[_vm._v(" "+_vm._s(_vm.tooltip(("scheduling_id-" + (row.id))))+" "+_vm._s(row.scheduling_id)+" ")])]}},{key:"confirmation_distance",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"text-center"},[(row.confirmation_distance)?_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(((row.confirmation_distance) + " KM")))]):_vm._e()])]}},{key:"department_name",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(((row.department_name) + ", " + (row.city_name))))])])]}},{key:"Opciones",fn:function(ref){
            var row = ref.row;
return [_c('a',{staticClass:"btn btn-danger btn-sm",attrs:{"href":"#","target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.redirect(("/guide/view-pdf/" + (row.id)))}}},[_vm._v("Pdf"),_c('i',{staticClass:"fa-regular fa-file-pdf"})])]}}])})],1)]),_c('AssignedOperated',{attrs:{"nameModal":_vm.nameModalOperated,"read":_vm.read,"permissionName":_vm.permissionNameOperated,"roleText":"Lector","type":1},on:{"asignedEvent":_vm.handledAsignedEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }