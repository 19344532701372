<template>
    <div>
        <button href="#" :disabled="filters && filters.p_company_id.length > 0 ? false : true" class="btn btn-warning " @click.prevent="show" >Asignar masivamente <i class="fas fa-lightbulb"></i></button>
        <modal :name="this.nameModal" :ref="this.nameModal" :clickToClose="false" height="auto">
            <div tabindex="-1" role="dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Asignación masiva</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <small class="text-muted ">Esta asignación se realizará de acuerdo a los filtros realizados y sólo aplicará para las lecturas que no tengan asignación</small>
                        

                        <b-form-group class="col-md-12 mt-2" v-if="assignment_type && assignment_type.id">
                            <label for="users" class="form-label">{{ assignment_type.name }}</label>
                            <v-select :options="users" :placeholder="`Buscar ${assignment_type.name}`" :reduce="item => item.id" label="name" @search="searchUser" v-model="user"></v-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="this.assignment_type && this.user ? false : true" type="button" class="btn btn-primary" @click.prevent="assign" >Asignar</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">Cancelar</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import { searchUsers } from "../../../services/user"

import { assignedMassive } from "../../../services/guide"

export default {
    props:{
        filters:{
            type:Object
        }
    },
    data(){
        return {
            nameModal: "assigned_massive",
            assignment_types:[
                {
                    id:1,
                    name:"Analista",
                    permissionName:"analyst"
                },
                {
                    id:2,
                    name:"Lector",
                    permissionName:"read"
                }
            ],
            assignment_type:{
                    id:2,
                    name:"Lector",
                    permissionName:"assigned_operated_guide"
                },
            users:[],
            user:null
        }
    },
    methods:{
        hide(){
            this.$modal.hide(this.nameModal);
        },
        show(){
            this.$modal.show(this.nameModal);
        },
        selectAssignamentType(){
            this.user = null
            this.users = []
        },
        async searchUser(search, loading){
            if (search.trim() == "") {
                return   
            }
            loading(true)
            let users = await searchUsers({search, permissionName: this.assignment_type.permissionName, type:this.type})
            this.users = users
            loading(false)
        },
        async assign(){
            console.log("guide/assigned_massive",{
                        type: this.assignment_type.id,
                        user_id: 1,
                        filters: this.filters
                    });
            if (this.assignment_type && this.user) {
                let loader = this.$loading.show({
                    container: this.$refs.loginRef
                });
                try {
                    let data = {
                        // type: this.assignment_type.id,
                        operatorId: this.user,
                        filters: this.filters
                    }
                    let resp = await assignedMassive(data)
                    if (resp.code == 200) {
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            html: 'Asignación masiva realizada correctamente ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.user = null
                        this.users = []
                        this.hide()
                        this.$emit("emitAssignedMassive",resp)
                    }
                    console.log(resp);
                    loader.hide()
                } catch (error) {
                    console.log(error);
                    loader.hide()
                }
            }else{
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    html: 'Faltan datos por completar',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
    }
}
</script>
