import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { can  } from '../functions/auth'

const canDirective = {}
canDirective.install = (Vue) => {    
    Vue.directive('can', {
        inserted(el, b, vnode) { 
            const store = vnode.context.$store;
            if (can(["xxxxxxxxxxxxx"], {superAdmin:"SUPER_ADMIN", roles: store.state.rolesbyuser, permissions: store.state.permissionsByUser})) {
                tippy(el, {
                    content:
                    `
                        <div>
                            ${b.value.doc ? '<p><b>DOC:</b> '+ b.value.doc + '<p></p>' : ''}
                            <p> <b>Pemiso necesario: </b> ${ b.value.permissions.join() } </p>
                        </div>
                        ` ,
                    allowHTML: true,
                    duration: [100, 200],
                    interactiveBorder: 30,
                    interactive:true
                })
            }
            let count = 0
            let pers = b.value.permissions;
            // VAlidar todos los permisos pasados por parametro
            pers.map(perm => {
                let is_can = can(perm)
                if (is_can) {
                    count++
                }
            })
            // Quitar el elemento si se cumple la condición.
            if (count == 0) { // Si count en igual a cero quiere decir que de los permisos que tiene el usuario no está ninguno de los que se necesitan para ver el recurso.
                // console.log(options,"Si count en igual a cero quiere decir que de los permisos que tiene el usuario no está ninguno de los que se necesitan para ver el recurso.");
                el.parentNode && el.parentNode.removeChild(el);
                el.remove()                
            }
        }
    })
}
export default canDirective;